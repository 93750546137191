import React, { Component } from 'react';
import "../css/project-comp.scss";

const orderFunc = (a, b) => {
    if (a.order > b.order)
        return 1
    else if (a.order < b.order)
        return -1
    return 0
}

const convertThumb = (img_path) => {
    const file_names = img_path.split("/")
    file_names[file_names.length - 1] = "thumb_" + file_names[file_names.length - 1]
    return file_names.join("/")
}

class MainComp extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        const { json_data, project_data, selected_style } = this.props;
        const shed_id = selected_style || json_data?.load_shed
        if (!shed_id) return <div></div>

        const loadObj = json_data[shed_id]
        if (!loadObj || !loadObj.selectedCustomColorItem) return <div></div>

        let colorItems = []
        if (loadObj.selectedCustomColorItem && Object.keys(loadObj.selectedCustomColorItem).length > 0) {
            Object.keys(loadObj.selectedCustomColorItem).map(key => {
                const customTextureModel = project_data.custom_texture_model.filter(src => src.id === loadObj.selectedCustomColorItem[key][0])
                if (customTextureModel && customTextureModel[0].texture_imgs && customTextureModel[0].texture_imgs.filter(img => img.img_type === 0).length > 1) {
                    const colorSet = loadObj.customColorSet.filter(set => set.id === key)[0]
                    colorItems.push({ id: key, texture: customTextureModel[0], colorSet, texture_id: loadObj.selectedCustomColorItem[key][1] });
                }
            })
        }

        if (!colorItems || colorItems?.length < 1) return <div></div>

        return (
            <div className="color-item">
                <div style={{ marginBottom: '2px' }}>
                    <span className='pricing-title'>Custom Surfaces</span>
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="select-panel">
                    {
                        colorItems?.map((colorSet, i) => (
                            <div key={i} className="custom-color-panel">
                                <p>{colorSet.colorSet.alt ? colorSet.colorSet.alt : colorSet.colorSet.title}</p>
                                <div className="custom-color-item select-panel">
                                    {colorSet.texture.texture_imgs.sort(orderFunc).map((texture, t_id) =>
                                        <div key={t_id}
                                            className={"color-item tooltip-div" + (colorSet.texture_id === texture.id ? " checked" : "")}
                                            style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL + convertThumb(texture.texture_img)})` }}
                                            onClick={this.selectCustomColorItem.bind(this, colorSet.id, texture.id)}
                                        >
                                            <span className="tooltip-content">{texture.img_key}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default MainComp;