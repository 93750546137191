import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: null,
  error: null,
  loading: false,
  user: localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : {},
  auth_projects: [],
  examples: [
    {
      title: "Classic Gable",
      img: require('../../assets/example/classic gable.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=4284ccf6-c18e-4890-ad83-7e667eb1c74f`,
      tabIndex: 0,
      index: "ex-0"
    },
    {
      title: "Gambrel",
      img: require('../../assets/example/update gambrel.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=56db3758-8410-4eca-8ff7-ce3a06ebc826`,
      tabIndex: 0,
      index: "ex-1"
    },
    {
      tabIndex: 0,
      index: "ex-2",
      title: "Lean-To",
      img: require('../../assets/example/learn-to.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=f4b773f7-2a7a-49e2-bcca-1f05057be43c`
    },
    {
      tabIndex: 0,
      index: "ex-3",
      title: "Modern Cottage",
      img: require('../../assets/example/modern cottage.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=3dc447f1-7bb4-4d91-b089-fbdb37115a19`
    },
    {
      tabIndex: 0,
      index: "ex-4",
      title: "Gable with Porch",
      img: require('../../assets/example/Gable with Porch.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=9b9e58b0-55d1-486c-94a1-cf1994f27dff`
    },

    {
      tabIndex: 0,
      index: "ex-5",
      title: "Gable with Porch",
      img: require('../../assets/example/Gambrel with Porch.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=e6c4d9f3-28aa-4f49-966c-fbe0d178a582`
    },

    {
      tabIndex: 0,
      index: "ex-6",
      title: "Deluxe Cabin",
      img: require('../../assets/example/deluxe cabin.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=73d4d802-c7c4-4a49-bb69-daa8369f03c3`
    },

    {
      tabIndex: 0,
      index: "ex-6",
      title: "Custom Barn",
      img: require('../../assets/example/custom barn.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=767fcbfc-4fb3-4766-9d8c-387bf8390362`
    },

    {
      tabIndex: 1,
      index: "ex-7",
      title: "Carports",
      img: require('../../assets/example/Carports.jpg'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=e552db95-ed28-4588-bf71-81a4a74b6fa0`
    },
    {
      tabIndex: 2,
      index: "ex-8",
      title: "Chicken Coop",
      img: require('../../assets/example/chicken-coop.png'),
      link: `${process.env.REACT_APP_SERVER_URL}/client/?project=b70017ec-68b9-44f6-a593-87c2ddfb877f`
    },
    {
      tabIndex: 3,
      index: "ex-9",
      title: "Play Set",
      img: require('../../assets/example/playset-demo.jpg'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/admin/Playset%20Demo`
    },
    {
      tabIndex: 2,
      index: "ex-10",
      title: "Walk-In Coop",
      img: require('../../assets/example/walk-in-coop.jpg'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/admin/Mellot_Coop_5x8`
    },
    {
      tabIndex: 2,
      index: "ex-11",
      title: "Deluxe Coop",
      img: require('../../assets/example/Deluxe Coop.jpg'),
      link: `${process.env.REACT_APP_SERVER_URL}/project/admin/KTBarns_WalkInCoop`
    }
  ]
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    user: {}
  });
};

const getAuth = (state, action) => {
  return updateObject(state, {
    user: action.user
  });
};


const getAuthProjects = (state, action) => {
  return updateObject(state, {
    auth_projects: action.arr
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.GET_AUTH:
      return getAuth(state, action);
    case actionTypes.GET_AUTH_PROJECTS:
      return getAuthProjects(state, action)
    default:
      return state;
  }
};

export default reducer;
