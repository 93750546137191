import React, { Component } from 'react';
import "../css/project-comp.scss";

const orderFunc = (a, b) => {
    if (a.order > b.order)
        return 1
    else if (a.order < b.order)
        return -1
    return 0
}

const convertThumb = (img_path) => {
    const file_names = img_path.split("/")
    file_names[file_names.length - 1] = "thumb_" + file_names[file_names.length - 1]
    return file_names.join("/")
}

class MainComp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,

        }
    }

    componentDidMount() {

    }

    handleInput = (e, shed_id, textureId) => {
        const { sidingColorCosts } = this.props;
        const key = textureId;
        const value = e.target.value?.replace('$ ', '').replace(/^0+/, '');
        sidingColorCosts[shed_id] = { ...sidingColorCosts[shed_id], [key]: value };

        this.props.handleChangeSidingColorCosts(sidingColorCosts);
    }

    minumumHandleInput = (e, shed_id) => {
        const { sidingColorMinumumCosts } = this.props;
        this.setState({ value: e.target.value });
        sidingColorMinumumCosts[shed_id] = e.target.value;
        // let minumumAmount = e.target.value;
        const type = 'sidingColorMinumumCosts';
        this.props.handleMultiplyer(type, sidingColorMinumumCosts);
    };

    validateRiskInput = (e) => {
        const input = e.target;
        let value = input.value.replace(/[^-0-9.]/g, '');
        input.value = value;
    }

    toggleMultiplyShow = (e, shed_id) => {
        const { isSidingColorSizeMultiplyers, isSidingColorSizePercentages } = this.props;
        isSidingColorSizeMultiplyers[shed_id] = e.target.checked;
        if (e.target.checked) {
            isSidingColorSizeMultiplyers[shed_id] = e.target.checked;
            isSidingColorSizePercentages[shed_id] = !e.target.checked;
        } else {
            isSidingColorSizePercentages[shed_id] = e.target.checked;
        }
        const type = 'isSidingColorSizeMultiplyers';
        this.props.handleMultiplyer(type, isSidingColorSizeMultiplyers);
    }

    togglePercentageShow = (e, shed_id) => {
        const { isSidingColorSizeMultiplyers, isSidingColorSizePercentages } = this.props;
        if (e.target.checked) {
            isSidingColorSizeMultiplyers[shed_id] = !e.target.checked;
            isSidingColorSizePercentages[shed_id] = e.target.checked;
        } else {
            isSidingColorSizePercentages[shed_id] = e.target.checked;
        }

        const type = 'isSidingColorSizePercentages';
        this.props.handlePercentage(type, isSidingColorSizePercentages);
    }

    render() {
        const { json_data, project_data, selected_style, sidingColorCosts, isSidingColorSizeMultiplyers, isSidingColorSizePercentages, sidingColorMinumumCosts } = this.props;
        const { value } = this.state;
        const shed_id = selected_style || json_data?.load_shed;
        if (!shed_id) return <div></div>

        const loadObj = json_data[shed_id];
        if (!loadObj || !loadObj.sidingColorSet) return <div></div>

        if (!loadObj.selectedSidingColorItem || !loadObj.sidingColorSet) return <div></div>

        let colorItems = [];
        loadObj.sidingColorSet.map(item => {
            const items = project_data.texture_model.filter(tex_item => tex_item.id === item.texture)[0]?.texture_imgs.filter(item => item.img_type === 0)
            if (items && items.length > 0) {
                colorItems.push(...items);
                colorItems?.sort(orderFunc);
            }
            return true;
        })

        // getting unique colorItems
        let flags = [], output = [];
        for (let i = 0; i < colorItems.length; i++) {
            if (flags[colorItems[i].id]) continue;
            flags[colorItems[i].id] = true;
            if (colorItems[i]) {
                output.push(colorItems[i]);
            }
        }

        colorItems = output;

        // let colorItem = undefined;
        // const colorIndex = loadObj.selectedSidingColorItem[0];
        // if (loadObj.sidingColorSet[colorIndex]) {
        //     colorItem = loadObj.sidingColorSet[colorIndex];
        // }

        // let colorItems = [];
        // if (colorItem) {
        //     colorItems = project_data.texture_model.filter(tex_item => tex_item.id === colorItem.texture)[0]?.texture_imgs.filter(item => item.img_type === 0);
        //     colorItems?.sort(orderFunc);
        // }

        if (!colorItems || colorItems?.length < 1) return <div></div>

        return (
            <div className="color-item">
                <div style={{ marginBottom: '2px', justifyContent: 'center' }}>
                    <span className='pricing-title'>Siding Color Options</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.toggleMultiplyShow(e, shed_id)}
                        checked={isSidingColorSizeMultiplyers[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>Multiply Price by SQ FT</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.togglePercentageShow(e, shed_id)}
                        checked={isSidingColorSizePercentages[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>By Percentage</span>
                    <span style={{ fontSize: '18px', marginLeft: '30px'}}>Minimum Charge</span>
                    <input
                        type="text"
                        className="minimum-amount"
                        placeholder=""
                        value={`$ ${sidingColorMinumumCosts[shed_id] || 0}`}
                        onChange={(e) => this.minumumHandleInput(e, shed_id)}
                        onInput={this.validateRiskInput}
                        style={{ margin: '0 0 2px 30px', width: '120px',textAlign: 'center', border: '1.4px solid #26455d', color: '#26455d', fontSize: '1.1rem'}}
                    />
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="select-panel">
                    {
                        colorItems?.map((texture, t_id) => (
                            texture.img_type === 0) &&
                            <div key={t_id} className='pricing-item-container tooltip-div'>
                                <div
                                    style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL + convertThumb(texture.texture_img)})`, width: '75px', height: '75px', borderRadius: '45px', cursor: 'pointer' }}
                                >
                                </div>
                                <span className="tooltip-content">{texture.img_key}</span>
                                <input
                                    type="text"
                                    className='pricing-item-price'
                                    placeholder=''
                                    value={`${isSidingColorSizePercentages[shed_id] ? '' : '$ '}${sidingColorCosts[shed_id] ? (sidingColorCosts[shed_id][texture.id] || '0') : '0'}`}
                                    onChange={(e) => this.handleInput(e, shed_id, texture.id)}
                                    onInput={this.validateRiskInput}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default MainComp;