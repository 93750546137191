import React from 'react'
import { Link } from 'react-router-dom'

import "../css/pricing.scss"

const blockItems = [
    {
        title: "1-3 Shed Styles",
        oldPrice: 250,
        price: 175,
    },
    {
        title: "4-8 Shed Styles",
        oldPrice: 500,
        price: 325,
    },
    {
        title: "9-15 Shed Styles",
        oldPrice: 700,
        price: 450,
    }

]

const PricePage = () =>
    <div className="pricing-comp">
        <div className="header-part header-example">
            <div className="header-title">
                <h2>Simple, Straight-Forward Pricing</h2>
                <h3>No annoying contracts or hidden startup fees.</h3>
            </div>
        </div>
        <div className='price-blocks'>
            
            <div className="header-title">
                <h3><b>special offer</b></h3>
                <h4>For a limited time, take advantage of our Startup Special.</h4>
                <h4>To lock in this discounted monthly rate, get in touch with us today!</h4>
                <br />
                <Link to="/contact-us" className="schedule-call">
                    <span >SCHEDULE A CALL</span>
                </Link>
            </div>

            <div className='price-content'>
                {
                    blockItems.map((item, index) => <div className='block-card' key={index}>
                        <div className='block-header'>
                            {item.title}
                        </div>
                        <div className='block-price'>
                            <p className='deprecated'>
                                ${item.oldPrice} / Monthly
                            </p>

                            <p className='current'>
                                ${item.price} / Monthly
                            </p>

                            <button className='learn-more' onClick={() => window.location.href = "/contact-us?type=" + index}>
                                Learn More
                            </button>
                        </div>
                        <p className='block-tab'>
                            Sell Customizable Sheds Online
                        </p>
                        <p className='block-tab'>
                            Capture More Leads
                        </p>
                        <p className='block-tab'>
                            Stunningly Realistic Views
                        </p>
                    </div>)
                }
            </div>
        </div>

        <div className='price-contact'>
            <h2>
                Pricing That Makes Sense
            </h2>
            <p>
                Unlike our competitors, we’ve built a product that fits your budget. There are no hidden fees, contracts, or exorbitant startup costs.
                Simple and transparent monthly pricing is what you can expect from us here at Digital Shed Builder.
                Let us build an amazing experience that is tailored to your business needs and that your customers will love!
                <br />
                <br />
                Have more than 15 Shed Styles? Contact us for premier pricing info.
            </p>
            <div>
                <Link to="/contact-us" className="learn-more">
                    <span >Contact Us</span>
                </Link>
            </div>
        </div>
    </div>

export default PricePage
