import React, { Component } from 'react';
import "../css/project-comp.scss";

class MainComp extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    handleInput = (e, shed_id, roofingId) => {
        const { roofingCosts } = this.props;
        const key = roofingId;
        const value = e.target.value?.replace('$ ', '').replace(/^0+/, '');
        roofingCosts[shed_id] = { ...roofingCosts[shed_id], [key]: value };

        this.props.handleChangeRoofingCosts(roofingCosts);
    }

    validateRiskInput = (e) => {
        const input = e.target;
        let value = input.value.replace(/[^-0-9.]/g, '');
        input.value = value;
    }

    toggleMultiplyShow = (e, shed_id) => {
        const { isRoofingSizeMultiplyers, isRoofingSizePercentages } = this.props;
        if (e.target.checked) {
            isRoofingSizeMultiplyers[shed_id] = e.target.checked;
            isRoofingSizePercentages[shed_id] = !e.target.checked;
        } else {
            isRoofingSizeMultiplyers[shed_id] = e.target.checked;
        }

        const type = 'isRoofingSizeMultiplyers';
        this.props.handleMultiplyer(type, isRoofingSizeMultiplyers);
    }

    togglePercentageShow = (e, shed_id) => {
        const { isRoofingSizeMultiplyers, isRoofingSizePercentages } = this.props;
        if (e.target.checked) {
            isRoofingSizeMultiplyers[shed_id] = !e.target.checked;
            isRoofingSizePercentages[shed_id] = e.target.checked;
        } else {
            isRoofingSizePercentages[shed_id] = e.target.checked;
        }

        const type = 'isRoofingSizePercentages';
        this.props.handlePercentage(type, isRoofingSizePercentages);
    }

    render() {
        const { json_data, project_data, selected_style, roofingCosts, isRoofingSizeMultiplyers, isRoofingSizePercentages } = this.props
        const shed_id = selected_style || json_data?.load_shed
        if (!shed_id) return <div></div>

        const loadObj = json_data[shed_id]
        if (!loadObj || !loadObj.roofingColorSet) return <div></div>

        let roofingItems = []
        if (shed_id && loadObj.roofingColorSet) {
            loadObj.roofingColorSet.map((item, roofingIndex) => {
                const roofingItem = project_data.roofing_model.filter(roofing => roofing.id === item.roofing.id)[0]
                roofingItems.push({ roofing: roofingItem, textures: [item.texture], roofingIndex })
                return true;
            })
        }

        return (
            <div className="color-item">
                <div style={{ marginBottom: '2px' }}>
                    <span className='pricing-title'>Roofing Options</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.toggleMultiplyShow(e, shed_id)}
                        checked={isRoofingSizeMultiplyers[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>Multiply Price by SQ FT</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.togglePercentageShow(e, shed_id)}
                        checked={isRoofingSizePercentages[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>By Percentage</span>
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="siding-item">
                    <div className="select-panel">
                        {
                            roofingItems.map((roofing, rIndex) =>
                                <div key={rIndex} className="pricing-item-container tooltip-div">
                                    <div className="size-image">
                                        <img src={process.env.REACT_APP_SERVER_URL + roofing.roofing.roofing_img}
                                            alt="Roofing" />
                                    </div>
                                    <span className="tooltip-content">{roofing.roofing.roofing_name}</span>
                                    <input
                                        type="text"
                                        className='pricing-item-price'
                                        placeholder=''
                                        value={`${isRoofingSizePercentages[shed_id] ? '' : '$ '}${roofingCosts[shed_id] ? (roofingCosts[shed_id][roofing.roofing.id] || '0') : '0'}`}
                                        onChange={(e) => this.handleInput(e, shed_id, roofing.roofing.id)}
                                        onInput={this.validateRiskInput}
                                    />
                                </div>
                            )
                        }
                    </div>

                </div>
            </div>
        )
    }
}

export default MainComp;