import React, { Component } from 'react';
import "../css/project-comp.scss";

import StyleItem from './StyleItem'
import SizeItem from './SizeItem';
import HeightSizeItem from './HeightSizeItem';
import SidingItem from './SidingItem';
import SidingColorItem from './SidingColorItem';
import TrimColorItem from './TrimColorItem';
import RoofingColorItem from './RoofingColorItem';
import CustomColorItem from './CustomColorItem';
import AssetItem from "./AssetItem";
import RoofingItem from './RoofingItem';

class MainComp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_style: props.json_data?.load_shed,
            sizeCosts: {},
            hideShowSizes: {},
            hideSizeFlag: {},
            heightCosts: {},
            sidingCosts: {},
            sidingColorCosts: {},
            trimColorCosts: {},
            roofingColorCosts: {},
            roofingCosts: {},
            assetCosts: {},
            assetFreeCosts: {},
            assetCreditCosts: {},
            isHeightSizeMultiplyers: {},
            isHeightSizePercentages: {},
            isSidingSizeMultiplyers: {},
            isSidingSizePercentages: {},
            isSidingColorSizeMultiplyers: {},
            sidingColorMinumumCosts: {},
            isSidingColorSizePercentages: {},
            isTrimColorSizeMultiplyers: {},
            isTrimColorSizePercentages: {},
            isRoofingColorSizeMultiplyers: {},
            isRoofingColorSizePercentages: {},
            isRoofingSizeMultiplyers: {},
            isRoofingSizePercentages: {},
            radioStatus:""
        }
    }

    updateCreditState = (newValue) => {
        this.setState({ 
            assetCreditCosts: newValue,
            assetFreeCosts: {}
         });
    }

    componentDidMount() {
        const { json_data } = this.props;
        const selected_styles = json_data?.selected_styles
        const { selected_style } = this.state;
        const shed_id = selected_style || json_data?.load_shed;
        if (shed_id) {
            let radioStatus, sizeCosts, heightCosts, sidingCosts, sidingColorCosts, trimColorCosts, roofingColorCosts, roofingCosts,assetCreditCosts, assetFreeCosts, assetCosts, isHeightSizeMultiplyers, isHeightSizePercentages, isSidingSizeMultiplyers, isSidingSizePercentages, isSidingColorSizeMultiplyers, sidingColorMinumumCosts, isSidingColorSizePercentages, isTrimColorSizeMultiplyers, isTrimColorSizePercentages, isRoofingColorSizeMultiplyers, isRoofingColorSizePercentages, isRoofingSizeMultiplyers, isRoofingSizePercentages;
            sizeCosts = {};
            heightCosts = {};
            sidingCosts = {};
            sidingColorCosts = {};
            trimColorCosts = {};
            roofingColorCosts = {};
            roofingCosts = {};
            assetCosts = {};
            assetCreditCosts = {};
            assetFreeCosts = {};
            isHeightSizeMultiplyers = {};
            isHeightSizePercentages = {};
            isSidingSizeMultiplyers = {};
            isSidingSizePercentages = {};
            isSidingColorSizeMultiplyers = {};
            sidingColorMinumumCosts = {};
            isSidingColorSizePercentages = {};
            isTrimColorSizeMultiplyers = {};
            isTrimColorSizePercentages = {};
            isRoofingColorSizeMultiplyers = {};
            isRoofingColorSizePercentages = {};
            isRoofingSizeMultiplyers = {};
            isRoofingSizePercentages = {};
            radioStatus = "";

            // sizeCosts = json_data[shed_id]?.sizeData?.sizeCosts || {};
            if (selected_styles) {
                Object.keys(selected_styles).map(style => {
                    sizeCosts[style] = json_data[style]?.sizeData?.sizeCosts || {};
                    heightCosts[style] = json_data[style]?.sizeData?.heightCosts || {};
                    isHeightSizeMultiplyers[style] = json_data[style]?.sizeData?.isHeightSizeMultiplyer || false;
                    isHeightSizePercentages[style] = json_data[style]?.sizeData.isHeightSizePercentage || false;
                    if (json_data[style] && json_data[style].selectedSidingColorItem) {
                        const sidingColorIndex = json_data[style].selectedSidingColorItem[0];
                        if (json_data[style]?.sidingColorSet) {
                            json_data[style]?.sidingColorSet.map(item => {
                                const sidingId = item.sidings[0].id;
                                if (!sidingCosts[style]) {
                                    sidingCosts[style] = {};
                                }
                                sidingCosts[style][sidingId] = item?.sidingCosts ? item?.sidingCosts : '0';
                                return true;
                            });
                            sidingColorCosts[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.sidingColorCosts || {};
                            isSidingSizeMultiplyers[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.isSidingSizeMultiplyer || false;
                            isSidingSizePercentages[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.isSidingSizePercentage || false;
                            isSidingColorSizeMultiplyers[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.isSidingColorSizeMultiplyer || false;
                            sidingColorMinumumCosts[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.sidingColorMinumumCosts || 0;
                            isSidingColorSizePercentages[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.isSidingColorSizePercentage || false;
                        }
                    }

                    if (json_data[style] && json_data[style].selectedTrimColorItem) {
                        const trimColorIndex = json_data[style].selectedTrimColorItem[0];
                        if (json_data[style]?.trimColorSet) {
                            trimColorCosts[style] = json_data[style]?.trimColorSet[trimColorIndex]?.trimColorCosts || {};
                            // isTrimColorSizeMultiplyers[style] = json_data[style]?.trimColorSet[trimColorIndex]?.isSidingColorSizeMultiplyer || false;
                            isTrimColorSizeMultiplyers[style] = json_data[style]?.trimColorSet[trimColorIndex]?.isTrimColorSizeMultiplyer || false;
                            // sidingColorMinumumCosts[style] = json_data[style]?.sidingColorSet[trimColorIndex]?.sidingColorMinumumCosts || 0;
                            isTrimColorSizePercentages[style] = json_data[style]?.trimColorSet[trimColorIndex]?.isTrimColorSizePercentage || false;
                        }
                    }

                    if (json_data[style] && json_data[style].selectedRoofingColorItem) {
                        const roofingColorIndex = json_data[style].selectedRoofingColorItem[0];
                        if (json_data[style]?.roofingColorSet) {
                            json_data[style]?.roofingColorSet.map(item => {
                                const roofingId = item.roofing.id;
                                if (!roofingCosts[style]) {
                                    roofingCosts[style] = {};
                                }
                                roofingCosts[style][roofingId] = item?.roofingCosts ? item?.roofingCosts : '0';
                                return true;
                            });
                            roofingColorCosts[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.roofingColorCosts || {};
                            isRoofingColorSizeMultiplyers[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.isRoofingColorSizeMultiplyer || false;
                            isRoofingColorSizePercentages[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.isRoofingColorSizePercentage || false;
                            isRoofingSizeMultiplyers[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.isRoofingSizeMultiplyer || false;
                            isRoofingSizePercentages[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.isRoofingSizePercentage || false;
                        }
                    }

                    if (json_data[style] && json_data[style].assetPosSet) {
                        const assetPosSet = json_data[style].assetPosSet;
                        assetPosSet.forEach((asset_model, index) => {
                            if (!assetCosts[style]) {
                                assetCosts[style] = {};
                            }
                            assetCosts[style][asset_model.asset.id] = asset_model.assetCost;
                            // let asset_type_list = asset_model.asset.asset_type_list;
                    
                            if (!assetFreeCosts[style]) {
                                assetFreeCosts[style] = {};
                            }
                            if(!asset_model.assetFreeCostNumber){
                                assetFreeCosts[style][asset_model.asset.id] = 0;
                            }else{
                                assetFreeCosts[style][asset_model.asset.id] = asset_model.assetFreeCostNumber;
                            }
                        });
                    }
                    if(json_data[shed_id]){
                        assetCreditCosts = json_data[shed_id].assetCreditNums;
                        radioStatus = json_data[shed_id].radioStatus;
                    }
                    return true;
                });
            }

            this.setState({
                selected_style: shed_id,
                sizeCosts,
                radioStatus,
                heightCosts,
                sidingCosts,
                sidingColorCosts,
                trimColorCosts,
                roofingColorCosts,
                roofingCosts,
                assetCosts,
                assetFreeCosts,
                assetCreditCosts,
                isHeightSizeMultiplyers,
                isHeightSizePercentages,
                isSidingSizeMultiplyers,
                isSidingSizePercentages,
                isSidingColorSizeMultiplyers,
                sidingColorMinumumCosts,
                isSidingColorSizePercentages,
                isTrimColorSizeMultiplyers,
                isTrimColorSizePercentages,
                isRoofingColorSizeMultiplyers,
                isRoofingColorSizePercentages,
                isRoofingSizeMultiplyers,
                isRoofingSizePercentages
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { json_data } = this.props;
        const selected_styles = json_data?.selected_styles
        const { selected_style } = this.state;
        const shed_id = json_data?.load_shed;
        if ((!selected_style && !prevState.selected_style) || shed_id !== prevProps?.json_data?.load_shed) {
            if (shed_id) {
                let radioStatus, sizeCosts, heightCosts, sidingCosts, sidingColorCosts, trimColorCosts, roofingColorCosts, roofingCosts, assetCreditCosts, assetFreeCosts, assetCosts, isHeightSizeMultiplyers, isHeightSizePercentages, isSidingSizeMultiplyers, isSidingSizePercentages, isSidingColorSizeMultiplyers, sidingColorMinumumCosts, isSidingColorSizePercentages, isTrimColorSizeMultiplyers, isTrimColorSizePercentages, isRoofingColorSizeMultiplyers, isRoofingColorSizePercentages, isRoofingSizeMultiplyers, isRoofingSizePercentages;
                sizeCosts = {};
                heightCosts = {};
                sidingCosts = {};
                sidingColorCosts = {};
                trimColorCosts = {};
                roofingColorCosts = {};
                roofingCosts = {};
                assetCosts = {};
                assetCreditCosts = {};
                assetFreeCosts = {};
                isHeightSizeMultiplyers = {};
                isHeightSizePercentages = {};
                isSidingSizeMultiplyers = {};
                isSidingSizePercentages = {};
                isSidingColorSizeMultiplyers = {};
                sidingColorMinumumCosts = {};
                isSidingColorSizePercentages = {};
                isTrimColorSizeMultiplyers = {};
                isTrimColorSizePercentages = {};
                isRoofingColorSizeMultiplyers = {};
                isRoofingColorSizePercentages = {};
                isRoofingSizeMultiplyers = {};
                isRoofingSizePercentages = {};
                radioStatus = "";

                // sizeCosts = json_data[shed_id]?.sizeData?.sizeCosts || {};
                if (selected_styles) {
                    Object.keys(selected_styles).map(style => {
                        sizeCosts[style] = json_data[style]?.sizeData?.sizeCosts || {};
                        heightCosts[style] = json_data[style]?.sizeData?.heightCosts || {};
                        isHeightSizeMultiplyers[style] = json_data[style]?.sizeData?.isHeightSizeMultiplyer || false;
                        isHeightSizePercentages[style] = json_data[style]?.sizeData?.isHeightSizePercentage || false;
                        if (json_data[style] && json_data[style].selectedSidingColorItem) {
                            const sidingColorIndex = json_data[style].selectedSidingColorItem[0];
                            if (json_data[style]?.sidingColorSet) {
                                json_data[style]?.sidingColorSet.map(item => {
                                    const sidingId = item.sidings[0].id;
                                    if (!sidingCosts[style]) {
                                        sidingCosts[style] = {};
                                    }
                                    sidingCosts[style][sidingId] = item?.sidingCosts ? item?.sidingCosts : '0';
                                    return true;
                                });
                                sidingColorCosts[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.sidingColorCosts || {};
                                isSidingSizeMultiplyers[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.isSidingSizeMultiplyer || false;
                                isSidingSizePercentages[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.isSidingSizePercentage || false;
                                isSidingColorSizeMultiplyers[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.isSidingColorSizeMultiplyer || false;
                                sidingColorMinumumCosts[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.sidingColorMinumumCosts || 0;
                                isSidingColorSizePercentages[style] = json_data[style]?.sidingColorSet[sidingColorIndex]?.isSidingColorSizePercentage || false;
                            }
                        }

                        if (json_data[style] && json_data[style].selectedTrimColorItem) {
                            const trimColorIndex = json_data[style].selectedTrimColorItem[0];
                            if (json_data[style]?.trimColorSet) {
                                trimColorCosts[style] = json_data[style]?.trimColorSet[trimColorIndex]?.trimColorCosts || {};
                                isTrimColorSizeMultiplyers[style] = json_data[style]?.trimColorSet[trimColorIndex]?.isTrimColorSizeMultiplyer || false;
                                isTrimColorSizePercentages[style] = json_data[style]?.trimColorSet[trimColorIndex]?.isTrimColorSizePercentage || false;
                            }
                        }

                        if (json_data[style] && json_data[style].selectedRoofingColorItem) {
                            const roofingColorIndex = json_data[style].selectedRoofingColorItem[0];
                            if (json_data[style]?.roofingColorSet) {
                                json_data[style]?.roofingColorSet.map(item => {
                                    const roofingId = item.roofing.id;
                                    if (!roofingCosts[style]) {
                                        roofingCosts[style] = {};
                                    }
                                    roofingCosts[style][roofingId] = item?.roofingCosts ? item?.roofingCosts : '0';
                                    return true;
                                });
                                roofingColorCosts[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.roofingColorCosts || {};
                                isRoofingColorSizeMultiplyers[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.isRoofingColorSizeMultiplyer || false;
                                isRoofingColorSizePercentages[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.isRoofingColorSizePercentage || false;
                                isRoofingSizeMultiplyers[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.isRoofingSizeMultiplyer || false;
                                isRoofingSizePercentages[style] = json_data[style]?.roofingColorSet[roofingColorIndex]?.isRoofingSizePercentage || false;
                            }
                        }

                        if (json_data[style] && json_data[style].assetPosSet) {
                            const assetPosSet = json_data[style].assetPosSet;
                            assetPosSet.forEach((asset_model, index) => {
                                if (!assetCosts[style]) {
                                    assetCosts[style] = {};
                                }
                                assetCosts[style][asset_model.asset.id] = asset_model.assetCost;
                        
                                if (!assetFreeCosts[style]) {
                                    assetFreeCosts[style] = {};
                                }
                                if(!asset_model.assetFreeCostNumber){
                                    assetFreeCosts[style][asset_model.asset.id] = 0;
                                }else{
                                    assetFreeCosts[style][asset_model.asset.id] = asset_model.assetFreeCostNumber;
                                }
                            });
                        }
                        
                        if(json_data[shed_id]){
                            assetCreditCosts = json_data[shed_id].assetCreditNums;
                            radioStatus = json_data[shed_id].radioStatus;
                        }
                        return true;
                    });
                }

                this.setState({
                    selected_style: shed_id,
                    sizeCosts,
                    radioStatus,
                    heightCosts,
                    sidingCosts,
                    sidingColorCosts,
                    trimColorCosts,
                    roofingColorCosts,
                    roofingCosts,
                    assetCosts,
                    assetCreditCosts,
                    assetFreeCosts,
                    isHeightSizeMultiplyers,
                    isHeightSizePercentages,
                    isSidingSizeMultiplyers,
                    isSidingSizePercentages,
                    isSidingColorSizeMultiplyers,
                    sidingColorMinumumCosts,
                    isSidingColorSizePercentages,
                    isTrimColorSizeMultiplyers,
                    isTrimColorSizePercentages,
                    isRoofingColorSizeMultiplyers,
                    isRoofingColorSizePercentages,
                    isRoofingSizeMultiplyers,
                    isRoofingSizePercentages
                });
            }
        }
    }

    handleChangeStyle = (selected_style) => {
        let { sizeCosts, sidingCosts, roofingCosts } = this.state;

        // sizeCosts[selected_style] = json_data[selected_style]?.sizeData?.sizeCosts || {};

        // let sidingCosts = {};
        // if (json_data[selected_style]?.sidingColorSet) {
        //     json_data[selected_style]?.sidingColorSet.map(item => {
        //         const sidingId = item.sidings[0].id;
        //         sidingCosts[sidingId] = item?.sidingCosts ? item?.sidingCosts : '0';
        //         return true;
        //     });
        //     // sidingColorCosts = json_data[selected_style]?.sidingColorSet[sidingColorIndex]?.sidingColorCosts || {};
        // }
        // let roofingCosts = {};
        // if (json_data[selected_style]?.roofingColorSet) {
        //     json_data[selected_style]?.roofingColorSet.map(item => {
        //         const roofingId = item.roofing.id;
        //         roofingCosts[roofingId] = item?.roofingCosts ? item?.roofingCosts : '0';
        //         return true;
        //     });
        //     // sidingColorCosts = json_data[selected_style]?.sidingColorSet[sidingColorIndex]?.sidingColorCosts || {};
        // }
        this.setState({
            selected_style,
            sizeCosts,
            sidingCosts,
            roofingCosts
        });
    }

    handleChangeSizeCosts = (sizeCosts) => {
        this.setState({ sizeCosts });
    }

    handleChangeRadioBtnStatus = (radioStatus) => {
        this.setState({ radioStatus: radioStatus});
    }

    handleChangeHideShowSize = (hideShowSizes, depthSizes) => {
        let hideSizeFlag = {};
        for (let size in hideShowSizes) {
            hideSizeFlag[size] = depthSizes.map(depthSize => {
                const exists = hideShowSizes[size].some(hideShowSize => {
                    return hideShowSize.scale === depthSize.scale
                });
                return { scale: exists ? 'false' : 'true', values: depthSize.values };
            });
        }

        this.setState({ hideShowSizes });
        this.setState({ hideSizeFlag });
    }

    handleChangeHeightCosts = (heightCosts) => {
        this.setState({ heightCosts });
    }

    handleChangeSidingCosts = (sidingCosts) => {
        this.setState({ sidingCosts });
    }

    handleChangeSidingColorCosts = (sidingColorCosts) => {
        this.setState({ sidingColorCosts });
    }

    handleChangeTrimColorCosts = (trimColorCosts) => {
        this.setState({ trimColorCosts });
    }

    handleChangeRoofingColorCosts = (roofingColorCosts) => {
        this.setState({ roofingColorCosts });
    }

    handleChangeRoofingCosts = (roofingCosts) => {
        this.setState({ roofingCosts });
    }

    handleAssetCosts = (assetCosts) => {
        this.setState({ assetCosts });
    }

    handleFreeAssetSelect = (assetFreeCostNum) => {
        this.setState({ 
            assetFreeCosts: assetFreeCostNum,
            assetCreditCosts : {}
         });
    }

    handleMultiplyer = (type, multiplyers) => {
        this.setState({
            [type]: multiplyers
        });
    }

    handlePercentage = (type, percentages) => {
        this.setState({
            [type]: percentages
        });
    }

    handleClearPricing = () => {
        this.setState({
            sizeCosts: {},
            hideSizeFlag: {},
            heightCosts: {},
            sidingCosts: {},
            sidingColorCosts: {},
            trimColorCosts: {},
            roofingColorCosts: {},
            roofingCosts: {},
            assetCosts: {},
            assetFreeCosts: {},
            assetCreditCosts: {},
            isHeightSizeMultiplyers: {},
            isHeightSizePercentages: {},
            isSidingSizeMultiplyers: {},
            isSidingSizePercentages: {},
            sidingColorMinumumCosts: {},
            isSidingColorSizeMultiplyers: {},
            isSidingColorSizePercentages: {},
            isRoofingColorSizeMultiplyers: {},
            isRoofingColorSizePercentages: {},
            isTrimColorSizeMultiplyers: {},
            isTrimColorSizePercentages: {},
            isRoofingSizeMultiplyers: {},
            isRoofingSizePercentages: {},
            radioStatus: "true"
        });
    }

    handleSavePricing = () => {
        let { json_data } = this.props;
        const { radioStatus, selected_style, hideSizeFlag, hideShowSizes,sizeCosts, assetCreditCosts, heightCosts, sidingCosts, sidingColorCosts, trimColorCosts, roofingColorCosts, roofingCosts, assetFreeCosts, assetCosts, isHeightSizeMultiplyers, isHeightSizePercentages, isSidingSizeMultiplyers, isSidingSizePercentages, isSidingColorSizeMultiplyers,sidingColorMinumumCosts, isSidingColorSizePercentages, isRoofingColorSizeMultiplyers, isRoofingColorSizePercentages, isTrimColorSizeMultiplyers, isTrimColorSizePercentages, isRoofingSizeMultiplyers, isRoofingSizePercentages } = this.state;
        const selected_styles = json_data?.selected_styles;
        const shed_id = selected_style || json_data?.load_shed;
        if (radioStatus == true){
            this.setState({ assetCreditCosts: {} });
        }else if (radioStatus == false){
            this.setState({ assetFreeCosts: {} });
        }
        Object.keys(selected_styles).map(style => {
            if (json_data[style]?.sizeData) {
                json_data[style].sizeData['sizeCosts'] = sizeCosts[style];
                json_data[style].sizeData['heightCosts'] = heightCosts[style];
                json_data[style].sizeData['isHeightSizeMultiplyer'] = isHeightSizeMultiplyers[style];
                json_data[style].sizeData['isHeightSizePercentage'] = isHeightSizePercentages[style];
            }

            if (json_data[style] && json_data[style].selectedSidingColorItem) {
                const sidingColorIndex = json_data[style]?.selectedSidingColorItem[0];

                if (json_data[style]?.sidingColorSet && json_data[style].sidingColorSet[sidingColorIndex]) {
                    json_data[style].sidingColorSet = json_data[style].sidingColorSet.map(item => {
                        const sidingId = item.sidings[0].id;
                        item['isSidingSizeMultiplyer'] = isSidingSizeMultiplyers[style];
                        item['isSidingSizePercentage'] = isSidingSizePercentages[style];
                        if (sidingCosts[style]) {
                            return { ...item, sidingCosts: sidingCosts[style][sidingId] };
                        }
                        delete item.sidingCosts;
                        return item;
                    });
                    // json_data[selected_style].sidingColorSet[sidingColorIndex]['sidingCosts'] = sidingCosts;
                    json_data[style].sidingColorSet = json_data[style].sidingColorSet.map(item => {
                        item['sidingColorCosts'] = sidingColorCosts[style];
                        item['isSidingColorSizeMultiplyer'] = isSidingColorSizeMultiplyers[style];
                        item['sidingColorMinumumCosts'] = sidingColorMinumumCosts[style];
                        debugger
                        item['isSidingColorSizePercentage'] = isSidingColorSizePercentages[style];
                        return item;
                    })
                    // json_data[style].sidingColorSet[sidingColorIndex]['sidingColorCosts'] = sidingColorCosts[style];
                }
            }

            if (json_data[style] && json_data[style].selectedTrimColorItem) {
                const trimColorIndex = json_data[style]?.selectedTrimColorItem[0];

                if (json_data[style]?.trimColorSet && json_data[style].trimColorSet[trimColorIndex]) {
                    json_data[style].trimColorSet = json_data[style].trimColorSet.map(item => {
                        item['trimColorCosts'] = trimColorCosts[style];
                        item['isTrimColorSizeMultiplyer'] = isTrimColorSizeMultiplyers[style];
                        item['isTrimColorSizePercentage'] = isTrimColorSizePercentages[style];
                        return item;
                    })
                }
            }

            if (json_data[style] && json_data[style].selectedRoofingColorItem) {
                const roofingColorIndex = json_data[style]?.selectedRoofingColorItem[0];

                if (json_data[style]?.roofingColorSet && json_data[style].roofingColorSet[roofingColorIndex]) {
                    json_data[style].roofingColorSet = json_data[style].roofingColorSet.map(item => {
                        const roofingId = item.roofing.id;
                        item['isRoofingSizeMultiplyer'] = isRoofingSizeMultiplyers[style];
                        item['isRoofingSizePercentage'] = isRoofingSizePercentages[style];
                        if (roofingCosts[style]) {
                            return { ...item, roofingCosts: roofingCosts[style][roofingId] };
                        }
                        delete item.roofingCosts;
                        return item;
                    });
                    json_data[style].roofingColorSet = json_data[style].roofingColorSet.map(item => {
                        item['roofingColorCosts'] = roofingColorCosts[style];
                        item['isRoofingColorSizeMultiplyer'] = isRoofingColorSizeMultiplyers[style];
                        item['isRoofingColorSizePercentage'] = isRoofingColorSizePercentages[style];
                        return item;
                    })
                    // json_data[style].roofingColorSet[roofingColorIndex]['roofingColorCosts'] = roofingColorCosts[style];
                }
            }
            let assetCreditCostsTmp = assetCreditCosts
            let assetFreeCostsTmp = assetFreeCosts
            if (radioStatus == true){
                assetCreditCostsTmp = {}
            }else if (radioStatus == false){
                assetFreeCostsTmp = {}
            }

            if (json_data[style] && json_data[style].assetPosSet) {
                const updatedAssetPosSet = json_data[style].assetPosSet.map((asset_model, index) => {
                    const assetCost = assetCosts[style] ? assetCosts[style][asset_model.asset.id] : '';
                    const assetFreeCostNumber = assetFreeCostsTmp[style] ? assetFreeCostsTmp[style][asset_model.asset.id] : '';
                    return { ...asset_model, assetCost, assetFreeCostNumber };
                });
                json_data[style].assetPosSet = updatedAssetPosSet;
            }
            if (json_data?.[style]) {
                    json_data[shed_id].assetCreditNums = assetCreditCostsTmp;
                    json_data[shed_id].radioStatus = radioStatus;  
            }
            return true;
        });
        if (json_data?.[shed_id].widthSizes) {
            json_data[shed_id].widthSizes.forEach(size => {
                const scale = size.scale;
                if (hideShowSizes[scale]) {
                    size.childHideShow = hideShowSizes[scale];
                    size.childHideShowFlag = hideSizeFlag[scale];
                }else if(!hideShowSizes[scale]){
                    
                    size.childHideShow = [];
                    size.childHideShowFlag = [];
                }
            });
        }

        this.props.handleSavePricing(json_data);
    }

    render() {
        const { json_data, project_data } = this.props;
        const { radioStatus, selected_style, sizeCosts, heightCosts, sidingCosts, sidingColorCosts, trimColorCosts, roofingColorCosts, roofingCosts, assetCreditCosts, assetFreeCosts, assetCosts, isHeightSizeMultiplyers, isHeightSizePercentages, isSidingSizeMultiplyers, isSidingSizePercentages, sidingColorMinumumCosts, isSidingColorSizeMultiplyers, isSidingColorSizePercentages, isTrimColorSizeMultiplyers, isTrimColorSizePercentages, isRoofingColorSizeMultiplyers, isRoofingColorSizePercentages, isRoofingSizeMultiplyers, isRoofingSizePercentages } = this.state;
        if (!project_data || !json_data) return <div></div>;
        return (
            <div className="asset-panel" >
                <div className='siding-panel'>
                    <StyleItem json_data={json_data} project_data={project_data} selected_style={selected_style} handleChangeStyle={this.handleChangeStyle} />
                </div>

                <div className='siding-panel'>
                    <SizeItem
                        json_data={json_data}
                        project_data={project_data}
                        selected_style={selected_style}
                        sizeCosts={sizeCosts}
                        handleChangeSizeCosts={this.handleChangeSizeCosts}
                        handleChangeHideShowSize={this.handleChangeHideShowSize}
                    />
                </div>

                <div className='siding-panel'>
                    <HeightSizeItem
                        json_data={json_data}
                        project_data={project_data}
                        selected_style={selected_style}
                        heightCosts={heightCosts}
                        isHeightSizeMultiplyers={isHeightSizeMultiplyers}
                        isHeightSizePercentages={isHeightSizePercentages}
                        handleChangeHeightCosts={this.handleChangeHeightCosts}
                        handleMultiplyer={this.handleMultiplyer}
                        handlePercentage={this.handlePercentage}
                    />
                </div>

                <div className='siding-panel'>
                    <SidingItem
                        json_data={json_data}
                        project_data={project_data}
                        selected_style={selected_style}
                        sidingCosts={sidingCosts}
                        isSidingSizeMultiplyers={isSidingSizeMultiplyers}
                        isSidingSizePercentages={isSidingSizePercentages}
                        handleChangeSidingCosts={this.handleChangeSidingCosts}
                        handleMultiplyer={this.handleMultiplyer}
                        handlePercentage={this.handlePercentage}
                    />
                </div>

                <div className='siding-panel'>
                    <SidingColorItem
                        json_data={json_data}
                        project_data={project_data}
                        selected_style={selected_style}
                        sidingColorCosts={sidingColorCosts}
                        isSidingColorSizeMultiplyers={isSidingColorSizeMultiplyers}
                        isSidingColorSizePercentages={isSidingColorSizePercentages}
                        sidingColorMinumumCosts={sidingColorMinumumCosts}
                        handleChangeSidingColorCosts={this.handleChangeSidingColorCosts}
                        handleMultiplyer={this.handleMultiplyer}
                        handlePercentage={this.handlePercentage}
                    />
                </div>

                <div className='siding-panel'>
                    <TrimColorItem
                        json_data={json_data}
                        project_data={project_data}
                        selected_style={selected_style}
                        trimColorCosts={trimColorCosts}
                        isTrimColorSizeMultiplyers={isTrimColorSizeMultiplyers}
                        isTrimColorSizePercentages={isTrimColorSizePercentages}
                        handleChangeTrimColorCosts={this.handleChangeTrimColorCosts}
                        handleMultiplyer={this.handleMultiplyer}
                        handlePercentage={this.handlePercentage}
                    />
                </div>

                <div className='siding-panel'>
                    <RoofingColorItem
                        json_data={json_data}
                        project_data={project_data}
                        selected_style={selected_style}
                        roofingColorCosts={roofingColorCosts}
                        isRoofingColorSizeMultiplyers={isRoofingColorSizeMultiplyers}
                        isRoofingColorSizePercentages={isRoofingColorSizePercentages}
                        handleChangeRoofingColorCosts={this.handleChangeRoofingColorCosts}
                        handleMultiplyer={this.handleMultiplyer}
                        handlePercentage={this.handlePercentage}
                    />
                </div>

                <div className='siding-panel'>
                    <RoofingItem
                        json_data={json_data}
                        project_data={project_data}
                        selected_style={selected_style}
                        roofingCosts={roofingCosts}
                        isRoofingSizeMultiplyers={isRoofingSizeMultiplyers}
                        isRoofingSizePercentages={isRoofingSizePercentages}
                        handleChangeRoofingCosts={this.handleChangeRoofingCosts}
                        handleMultiplyer={this.handleMultiplyer}
                        handlePercentage={this.handlePercentage}
                    />
                </div>

                {/* <div className='siding-panel'>
                    <CustomColorItem
                        json_data={json_data}
                        project_data={project_data}
                        selected_style={selected_style}
                    />
                </div> */}

                <div className='siding-panel'>
                    <AssetItem
                        json_data={json_data}
                        project_data={project_data}
                        radioStatus = {radioStatus}
                        selected_style={selected_style}
                        assetCosts={assetCosts}
                        assetFreeCosts={assetFreeCosts}
                        assetCreditCosts={assetCreditCosts}
                        handleAssetCosts={this.handleAssetCosts}
                        handleFreeAssetSelect={this.handleFreeAssetSelect}
                        updateCreditState={this.updateCreditState}
                        handleChangeRadioBtnStatus = {this. handleChangeRadioBtnStatus}
                    />
                </div>

                <div className='pricing-title-bottom'></div>

                <div className='pricing-footer'>
                    <button className='pricing-button clear' onClick={this.handleClearPricing}>
                        Clear All
                    </button>
                    <button className='pricing-button save' onClick={this.handleSavePricing}>
                        Save
                    </button>
                </div>

            </div>
        )
    }
}

export default MainComp;