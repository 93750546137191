import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  open: false,
  types: "success",
  message: "This is success message."
};

const successOpen = (state, action) => {
  return updateObject(state, action.data);
};

const successClose = (state) => {
  return updateObject(state, {
    open: false
  });
};

const errorOpen = (state, action) => {
  return updateObject(state, action.data);
};

const errorClose = (state) => {
  return updateObject(state, {
    open: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUCCESSALERT_OPEN:
      return successOpen(state, action);
    case actionTypes.SUCCESSALERT_CLOSE:
      return successClose(state);
    case actionTypes.ERRORALERT_OPEN:
      return errorOpen(state, action);
    case actionTypes.ERRORALERT_CLOSE:
      return errorClose(state);
    default:
      return state;
  }
};

export default reducer;
