import React, { useState, useEffect } from 'react'
// import {Link} from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import { useDispatch } from "react-redux";
import { errorOpen } from '../../store/actions/alerts'
import { authLogin } from '../../store/actions/auth'
import { FaTimes } from 'react-icons/fa';

import "../css/payment.scss";

const titles = [
    "Set Up Your Account To Get Started", "Account Details", "Order Completed", "Manage Your Subscriptions"
];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const dd = new Date("Jan/1/1900");

const sDate = `${months[dd.getMonth()]}/${dd.getDate()}/${dd.getFullYear()}`

const Comp = () => {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0);
    const [user, setUser] = useState({ firstname: "", lastname: "" });

    const [password, setPassword] = useState("");

    const [cardNumber, setCardNumber] = useState("");
    const [cardCode, setCardCode] = useState("");

    const [subAmount, setSubAmount] = useState("0");

    const [subStartDate, setSubStartDate] = useState(sDate);

    const [subscriptionId, setSubscriptionId] = useState("");

    const [description, setDescription] = useState("Create Customer Payment Profile");
    const [validCard, setValidCard] = useState({ accountNumber: "" });
    const [subTitle, setSubTitle] = useState("1-3 Shed Styles");

    const [addCard, setAddCard] = useState(true);
    const [termsCheck, setTermsCheck] = useState(false);

    const [month, setMonth] = useState(0);

    const [years, setYears] = useState([]);
    const [year, setYear] = useState(0);

    const [showError, setShowError] = useState("");
    const [accountError, setAccountError] = useState("");

    const [subscriptionError, setSubscriptionError] = useState("");

    const [modalShow, setModalShow] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // GET request using fetch inside useEffect React hook
        setIsLoading(true);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const customerId = urlParams.get('customer');
        const stepValue = urlParams.get('step') * 1;

        if (stepValue)
            setStep(stepValue);

        const d = new Date();
        const yearArr = [];
        let i;
        for (i = d.getFullYear(); i < d.getFullYear() + 20; i++) yearArr.push(i);
        setYears(yearArr);
        setMonth(d.getMonth() + 1);
        setYear(d.getFullYear());

        fetch(`${process.env.REACT_APP_SERVER_URL}/get_customer_by_id/${customerId}`)
            .then(response => response.json())
            .then(data => {
                const res = data.data.result;
                console.log("subscription state : ", res);
                if (res.active_status === 1) {
                    if (res.subscribe_status === 1 && (res.subscribe_id && res.subscribe_id !== '')) {
                        setStep(3);
                    } else {
                        setStep(1);
                    }
                } else {
                    setStep(0);
                }
                setIsLoading(false);
                setUser(res);
                setSubAmount(res.subscribe_amount?.value);

                console.log('res.subscription: ', res.subscription);

                if (res.subscription) {

                    const subscription = res.subscription;
                    const startDate = new Date(subscription.paymentSchedule.startDate);

                    const month = d.getMonth() < 11 ? d.getMonth() + 1 : 0;
                    const year = d.getMonth() === 11 ? (d.getFullYear() + 1) : d.getFullYear();

                    setSubStartDate(`${months[month]}/${startDate.getDate()}/${year}`);
                    setSubscriptionId(res.subscribe_id);
                    setDescription(res.subscribe_amount.description_payment);
                    setSubTitle(res.subscribe_amount.description);

                } else if (stepValue === 2) {
                    dispatch(errorOpen("You have not any subscription."))
                    return;
                }
            });
    }, [dispatch]);

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const updatePassword = () => {
        if (password.length < 8) {
            setAccountError("Password should be more than 8 characters.")
            return
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const customerId = urlParams.get('customer');
        const data = {
            "customer-id": customerId,
            password
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }

        fetch(`${process.env.REACT_APP_SERVER_URL}/activate_account/`, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Activate Account Failed!");
            })
            .then(data => {
                if (data.status === 200) {
                    setStep(1);
                    dispatch(authLogin(user.useremail, password));
                }
            })
            .catch(error => {
                // console.log("error : ", error)
                setAccountError(`${error}`);
            })
    }

    const onChangeMonth = (e) => {
        setMonth(e.target.value);
    }

    const onChangeYear = (e) => {
        setYear(e.target.value);
    }

    const onChangeCardNumber = (e) => {
        setCardNumber(e.target.value);
    }

    const onChangeCardCode = (e) => {
        setCardCode(e.target.value);
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
    }

    const onchangeTermsValue = (e) => {
        setTermsCheck(!termsCheck);
    }

    const addNewCard = (e) => {
        e.preventDefault();
        if (cardNumber.length < 6) {
            setShowError("Invalid card number.")
            return
        }

        setAddCard(false);
    }

    const createNewSubscription = (e) => {
        e.preventDefault();
        if (!termsCheck) return;

        const customerId = user.id
        const data = {
            "customer-id": customerId,
            "card-number": cardNumber,
            "card-code": cardCode,
            "expire-date": `${year}-${month}`,
            "ref-id": Date.now()
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/authorize_card/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("authorize_card : ", data);
                if (data.data.messages.resultCode === "Error") {
                    setShowError(data.data.messages.message[0].text);
                    return;
                }
                setValidCard(data.data.transactionResponse);
                setShowError("");

                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const customerId = urlParams.get('customer');
        
                const d = new Date();
        
                const data1 = {
                    "customer-id": customerId,
                    "card-number": cardNumber,
                    "expire-date": `${year}-${month}`,
                    "trans-id": validCard.transId,
                    "start-date": `${d.getMonth() === 11 ? (d.getFullYear() + 1) : d.getFullYear()}-${d.getMonth() !== 10 ? '0' : ''}${d.getMonth() < 11 ? (d.getMonth() + 2) : (d.getMonth() - 10)}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`,
                    "ref-id": Date.now(),
                    description
                };
        
                const requestOptions1 = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data1)
                };
        
                fetch(`${process.env.REACT_APP_SERVER_URL}/create_payment_profile/`, requestOptions1)
                    .then(response => response.json())
                    .then(data => {
                        console.log("create_payment_profile : ", data.data);
                        if (data.status === 200) {
                            if (data.data.subscription.messages.resultCode === "Error") {
                                setShowError(data.data.subscription.messages.message[0].text);
                                return;
                            }
                            let userItem = { ...user };
                            userItem['subscriptionId'] = data.data.subscription.subscriptionId;
        
                            fetch(`${process.env.REACT_APP_SERVER_URL}/get_customer_by_id/${customerId}`)
                                .then(response => response.json())
                                .then(data => {
                                    console.log("get_customer_by_id : ", data);
                                    const res = data.data.result;
                                    setUser(res);
                                    if (res.subscription) {
                                        const subscription = res.subscription;
                                        userItem.subAmount = subscription.amount;
                                        userItem.subDescription = subscription.name;
        
                                        setSubAmount(subscription.amount);
                                        const startDate = new Date(subscription.paymentSchedule.startDate);
                                        setSubStartDate(`${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`);
                                        userItem.startDate = `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`;
                                        userItem.paymentProfile = subscription.profile.customerProfileId;
                                        userItem.creditCardNumber = subscription.profile.paymentProfile.payment.creditCard.cardNumber;
                                        setDescription(user.subscribe_amount.description_payment);
                                        setUser(userItem);
                                        setSubscriptionId(res.subscribe_id);
                                    } else if (step === 2) {
                                        setShowError("You have not any subscription.");
                                        return
                                    }
                                });
                            setStep(2);
                        }
                        else
                            setShowError(data.message);
                    })
                    .catch(error => {
                        console.log(error)
                        if (error.response) setShowError(error.response.data.message);
                    })
            })
            .catch(error => {
                setShowError(error.response.data.message);
            })
    }

    const cancelSubscription = () => {
        if (!subscriptionId) {
            setStep(1);
            setModalShow(false);
            return;
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const customerId = urlParams.get('customer');
        const data = {
            "customer-id": customerId,
            "ref-id": Date.now()
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/cancel_subscription/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setModalShow(false);
                if (data.status === 200) {
                    if (data.data.messages.resultCode === "Error") {
                        setSubscriptionError(data.data.messages.message[0].text);
                        return
                    }
                    setStep(1);
                }
                else
                    setSubscriptionError(data.message);
            })
            .catch(error => {
                setModalShow(false);
                setSubscriptionError(error.response.data.message);
            })

    }

    return <div className="payment-comp">
        <div className='gap-head'></div>
        <div className='title-comp'>
            <label>
                {titles[step]}
            </label>
        </div>
        <div className="gradient-line"></div>
        <div className='price-contact'>
            {(step === 0) &&
                <>
                    <br />
                    {accountError && <div className="form-group error-dialog account-error">
                        <p>
                            Sorry! <br /> {accountError}
                        </p>
                        <span onClick={() => setAccountError("")}>
                            <FaTimes />
                        </span>
                    </div>
                    }
                    <h3>
                        Account Info:
                    </h3>
                    <div className='account-info'>
                        <p>
                            Your Account Email Is :
                        </p>
                        <input type='text' value={user.useremail ? user.useremail : ""} disabled />
                        <p>
                            Create A Password :
                        </p>
                        <input type='password' value={password} onChange={onChangePassword} />
                        <div className='button-div'>
                            <button onClick={() => updatePassword()}>
                                Activate Account
                            </button>
                        </div>
                    </div>

                </>}

            {(step === 1 && <>

                <div className='description'>
                    <label className='level'>
                        {user.subscribe_amount ? user.subscribe_amount.title : ""}
                    </label>
                    <label className='price'>
                        ${user.subscribe_amount ? user.subscribe_amount.value : 0}/Month
                    </label>
                    <label className='styles'>
                        {user.subscribe_amount ? user.subscribe_amount.description : ""}
                    </label>
                </div>

                <div className="modal-content">
                    <div id="form-payment" action="" method="post" className="form-horizontal">
                        <div className="modal-body">
                            {showError && <div className="form-group error-dialog">
                                <p>
                                    Sorry! <br /> {showError}
                                </p>
                                <span onClick={() => setShowError("")}>
                                    <FaTimes />
                                </span>
                            </div>
                            }
                            {
                                (!addCard) ? <>
                                    <div className="form-group ">
                                        <label htmlFor="coupon" className="control-label">Discription</label>
                                        <div className="col-sm-9 col-md-9 control-coupon">
                                            <input className="form-control" id="coupon" maxLength="20" value={description} type="text" onChange={onChangeDescription} />
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label htmlFor="id_card" className="control-label">Active Card</label>
                                        <div id="div_id_card" className="col-sm-9 col-md-9">
                                            <div className="input-group">
                                                <input className="form-control" id="id_card" name="card" disabled value={validCard.accountNumber} />
                                                <span className="input-group-btn">
                                                    <button onClick={() => setAddCard(true)}
                                                        className="btn btn-primary pull-right" >
                                                        Add New Card
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </> : <>
                                    <div className="form-group">
                                        <label htmlFor="coupon col-sm-3" className="control-label">Card Number</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" maxLength="20" type="text" value={cardNumber} onChange={onChangeCardNumber} />
                                        </div>
                                    </div>

                                    <div className="form-group ">
                                        <label htmlFor="coupon col-sm-3" className="control-label">Expiration Date</label>
                                        <div className="col-sm-9 date-box">
                                            <select className='select-month' onChange={onChangeMonth} value={month}>
                                                {months.map((item, index) => <option key={item} value={index + 1}>{index + 1}</option>)}
                                            </select>
                                            <span>/</span>
                                            <select className='select-year' onChange={onChangeYear} value={year} >
                                                {years.map(item => <option key={item}>{item}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <label htmlFor="coupon col-sm-3" className="control-label">CVV2</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" maxLength="20" type="text" value={cardCode} onChange={onChangeCardCode} />
                                        </div>
                                    </div>
                                </>
                            }
                            {/* <img src={require("../../assets/imgs/cards.png")} alt="cards" style={{ width: "100%" }} /> */}
                            {
                                (addCard) ? <></> :
                                    <div className='terms-service'>
                                        <h6>
                                            Terms and Conditions
                                        </h6>
                                        <div className='contents'>
                                            By purchasing you acknowledge this is a digital product.
                                            No physical terms will be mailed to you.
                                            You will be able to access your projects from your member page once they have been completed.
                                        </div>
                                        <div className='check-box'>
                                            <div>
                                                <input type="checkbox" checked={termsCheck} onChange={onchangeTermsValue} /> <label>I accept the terms</label>
                                            </div>
                                            <label onClick={() => window.open("/privacy-policy", '_blank')}>
                                                Refund Policy
                                            </label>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="modal-footer">
                            {
                                (addCard) ? <button onClick={addNewCard} className="btn btn-success">ADD CARD</button> :
                                    <button onClick={createNewSubscription} className="btn btn-success">PROCESS PAYMENT</button>}
                        </div>
                    </div>
                </div>


            </>)}

            {(step === 2) && <>
                <div className='modal-content payment-complete'>
                    <div className='payment-header'>
                        <p>
                            You have successfully completed your payment!
                        </p>
                        <p>
                            This is your receipt, so please make sure you print/save this page for your records.
                        </p>
                        <p>
                            It may take up 24 hours for your 3D View to become available online.
                        </p>
                    </div>
                    <div className='payment-content'>
                        <h1>Shed Builder</h1>
                        <div className='contents-list'>
                            <div className='contents-item'>
                                <h5>Invoice For</h5>
                                <p>{user.useremail}</p>
                            </div>
                            <div className='contents-item'>
                                <h5>Invoice ID</h5>
                                <p>{subscriptionId}</p>
                            </div>
                            <div className='contents-item'>
                                <h5>Payment Type</h5>
                                <p>MasterCard</p>
                            </div>
                            <div className='contents-item'>
                                <h5>Start Date</h5>
                                <p>{user.startDate}</p>
                            </div>
                            <div className='contents-item'>
                                <h5>Customer ID</h5>
                                <p>{user.paymentProfile}</p>
                            </div>
                            <div className='contents-item'>
                                <h5>Card Number</h5>
                                <p>{user.creditCardNumber}</p>
                            </div>
                        </div>
                    </div>
                    <div className='subscription-content'>
                        <div>
                            <table >
                                <thead>
                                    <tr>
                                        <th>Subscription</th>
                                        <th>Billing Cycle</th>
                                        <th >Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{description}</td>
                                        <td>Monthly</td>
                                        <td>${user.subAmount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <p className='total-amount'>Total: ${user.subAmount}</p>
                    <div className='payment-continue'>
                        <button className='button-continue' onClick={() => window.location.href = "/member-page"}>
                            Continue
                        </button>
                    </div>

                </div>
            </>}

            {(step === 3) && <>

                {subscriptionError && <div className="subscription-error error-dialog">
                    <p>
                        Sorry! <br /> {subscriptionError}
                    </p>
                    <span onClick={() => setSubscriptionError("")}>
                        <FaTimes />
                    </span>
                </div>
                }
                {
                    isLoading ?
                        <div className='modal-content subscription-card'>Loading...</div>
                        :
                        <div className='modal-content subscription-card'>
                            <div className='block-header'>
                                {subTitle}
                            </div>
                            <div className='block-price'>
                                <p>
                                    ${subAmount} / Monthly
                                </p>
                                <p>
                                    Renewal Date: {subStartDate}
                                </p>
                            </div>

                            {/* <p className='block-tab'>
                        <button onClick={cancelSubscription} disabled={!subscriptionId} className={(subscriptionId) ? "" : "Disable"}>
                            update payment info
                        </button>
                    </p>
                    <p className='block-tab'>
                        <button onClick={cancelSubscription} disabled={!subscriptionId} className={(subscriptionId) ? "" : "Disable"}>
                            upgrade / downgrade
                        </button>
                    </p> */}

                            <p className='block-tab'>
                                <button onClick={() => setModalShow(true)} disabled={!subscriptionId && user.subscribe_status} className={(subscriptionId || user.subscribe_status) ? "Cancel" : "Create"}>
                                    {(subscriptionId || user.subscribe_status) ? "Cancel" : "Activate"} Subscription
                                </button>
                            </p>
                        </div>
                }

            </>}


            {(step < 3) && <div className='status-bar'>
                <div className="stages-wrapper">
                    <div className={"stage" + ((step === 0) ? " active" : "")}>
                        1
                        <div className="desc">Account</div>
                    </div>
                    <div className="stage-path"></div>
                    <div className={"stage" + ((step === 1) ? " active" : "")}>
                        2
                        <div className="desc">Payment</div>
                    </div>
                    <div className="stage-path"></div>
                    <div className={"stage" + ((step === 2) ? " active" : "")}>
                        3
                        <div className="desc">Publish</div>
                    </div>
                </div>
            </div>
            }


            {
                (step === 3) && <div className='space-div'></div>
            }

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='cancel-modal'
            >

                <Modal.Header>
                    <Modal.Title>
                        Confirmation!
                    </Modal.Title>
                    <button className='btn-exit' onClick={() => setModalShow(false)}>
                        <FaTimes />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div className="user-auth">
                        <h6>
                            Are you really sure to cancel your subscription?
                        </h6>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={cancelSubscription}>Ok</Button>
                    <Button onClick={() => setModalShow(false)} >Cancel</Button>
                </Modal.Footer>

            </Modal>

        </div>
    </div>
}


export default Comp;
