import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const LayoutOne = ({ children }) =>
    <div className="layout-one">
        {children}
        <div className='footer-div'>
            <p>
                © Copyright 2022 Digital Shed Builder Inc, all rights reserved.
            </p>
            <div>
                <ul className="nav navbar-nav navbar-right">
                    <li className="hidden-xs">
                        <a href="https://www.facebook.com/people/Digital-Shed-Builder/100081949742401/" target="_blank" rel="noopener noreferrer" >
                            <FaFacebookF />
                        </a>
                    </li>

                    <li className="hidden-xs">
                        <a href="https://instagram.com/digitalshedbuilder?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                    </li>
                </ul>
            </div>
            <p>
                <span onClick={()=>window.location.href = "/terms-condition"}>
                    Terms of Service
                </span>
                <span onClick={()=>window.location.href = "/privacy-policy"}>
                    Privacy Policy
                </span>
            </p>
        </div>
    </div>

const LayoutTwo = ({ children }) =>
    <div>
        <h3>Layout Two</h3>
        {children}
    </div>

export { LayoutOne, LayoutTwo }