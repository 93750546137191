import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import userIcon from '../../assets/userIcon.png';
import logo from '../../DSB_ICON.png';
import '../css/AffiliatePage.scss';

class AffiliatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProject: {},
      quotesShow: false,
      modalShow: false,
      selectedQuote: {},
      collapseOpen: true,
      pViewCollapseOpen: true,
      pricingViewCollapseOpen: true,
      timePeriod: "Year",
      years: [],
      selectedYear: -1,
      selectedMonth: -1,
      selectedDay: -1,
      CustomerViewData: {
        labels: Array(12).fill(0).map((_, i) => `Month ${i + 1}`),
        datasets: [
          {
            label: 'Views',
            data: Array(12).fill(0),
            backgroundColor: '#26455d',
          },
        ],
      },
      affiliate: {},
      customers: [],
      totalRevenue: 0,
      loading: true,
    };
  }

  async componentDidMount() {
    const appHeader = document.querySelector('header');
    if (appHeader) {
      appHeader.style.display = 'none';
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const customerId = urlParams.get('customer');
    const adminPassword = urlParams.get('password');

    if (!customerId) {
      window.location.href = '/';
      return;
    }

    if (!adminPassword) {
      window.location.href = '/';
      return;
    }

    try {
      const reqUrl = `${process.env.REACT_APP_SERVER_URL}/api/affiliates/${customerId}`;
      const response = await axios.get(reqUrl);
      const affiliate = response.data;
      const totalRevenue = affiliate.customers.reduce((sum, customer) => {
        const monthsRemaining = this.calculateMonthsRemaining(customer.created_at, affiliate.duration);
        return monthsRemaining > 0
          ? sum + parseFloat(customer.amount) * (parseFloat(affiliate.percentage) / 100)
          : sum;
      }, 0);
      this.setState({
        affiliate,
        customers: affiliate.customers,
        totalRevenue,
        loading: false
      }, this.setBottomTrHeight); // Call the height adjustment function after setting the state
    } catch (error) {
      console.error('Error fetching affiliate data:', error);
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    const appHeader = document.querySelector('.App-header');
    if (appHeader) {
      appHeader.style.display = '';
    }
  }

  calculateMonthsRemaining(customerCreatedAt, affiliateDuration) {
    const currentDate = new Date();
    const createdAtDate = new Date(customerCreatedAt);
    const durationInMonths = affiliateDuration * 12; // Convert years to months
    const elapsedTimeInMonths = (currentDate.getFullYear() - createdAtDate.getFullYear()) * 12 + (currentDate.getMonth() - createdAtDate.getMonth());
    const remainingMonths = durationInMonths - elapsedTimeInMonths;

    return Math.max(remainingMonths, 0); // Ensure it doesn't return negative months
  }

  setBottomTrHeight = () => {
    const table = document.querySelector('.content table');
    const bottomRow = document.querySelector('.bottom-tr');
    const totalHeight = window.innerHeight;

    // Calculate the total height of all rows except the bottom one
    let otherRowsHeight = 0;
    const rows = table.querySelectorAll('tbody tr');
    rows.forEach(row => {
      if (!row.classList.contains('bottom-tr')) {
        otherRowsHeight += row.offsetHeight;
      }
    });

    // Set the height of the bottom-tr
    const bottomRowHeight = totalHeight - otherRowsHeight;
    bottomRow.style.height = `${bottomRowHeight}px`;
  }

  render() {
    const { affiliate, customers, totalRevenue, loading } = this.state;

    if (loading) {
      return <p>Loading...</p>;
    }

    const currentMonth = new Date().toLocaleString('default', { month: 'long' });

    return (
      <div>
        <div className="affiliate-page">
          <div className="sidebar">
            <div className="header-icon">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="affiliate-info">
              <p className='headline'>Affiliate Program</p>
              <div>
                <img src={userIcon} alt="Description of image" />
              </div>
              <p className='affiliate_name'>{affiliate.name}</p>
              <a href={affiliate.landing_page_url}>Landing Page URL</a>
            </div>
          </div>
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Subscription</th>
                  <th>Percentage</th>
                  <th>Months Remaining</th>
                  <th>Earned Amount</th>
                </tr>
              </thead>
              <tbody>
                {customers.map(customer => {
                  const monthsRemaining = this.calculateMonthsRemaining(customer.created_at, affiliate.duration);
                  const earnedAmount = monthsRemaining > 0 ? (customer.amount * (affiliate.percentage / 100)).toFixed(2) : '0.00';
                  return (
                    <tr key={customer.id}>
                      <td>{customer.firstname +" "+ customer.lastname}</td>
                      <td>${customer.amount}</td>
                      <td>{affiliate.percentage}%</td>
                      <td>{monthsRemaining}</td>
                      <td>${earnedAmount}</td>
                    </tr>
                  );
                })}
                <tr className='bottom-tr'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div className="total-revenue">
              <h3>Total Revenue For {currentMonth}: ${totalRevenue.toFixed(2)}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AffiliatePage;
