import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


const options = {
  responsive: true,
  showTooltips: true,
  plugins: {
    legend: {
      display: false,
      position: 'right' as const,
    },
    title: {
      display: false,
      text: 'Referals',
    }
  },
};

// const data = {
//   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//   datasets: [
//     {
//       label: '%',
//       data: [12, 19, 3, 5, 2],
//       backgroundColor: [
//         'rgba(255, 164, 0, 0.6)',
//         'rgba(0, 168, 215, 0.6)',
//         'rgba(110, 124, 124, 0.6)',
//         'rgba(223, 223, 223, 0.6)',
//         '#6e7c7c',
//       ],
//       borderWidth: 1,
//     },
//   ],
// };



export default function App(props: any) {
  return <div>
    <h3>Referral Links</h3>
    <div className='pie-show'>
      <div>
        <Pie options={options} data={props.data} redraw={props.redraw} />
      </div>

      <ul className='pie-list'>
        {
          props.data.labels.map((label: any, i: any) => <li key={label}>
            <div style={{ backgroundColor: props.data.datasets[0].backgroundColor[i] }}></div>
            <label className='pie-name'>
              {label}
            </label>
            <label className='percentage'>
              ( {props.data.views[i]} )
            </label>
          </li>)
        }
      </ul>
    </div>

  </div>;
}
