import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel';
import { useSelector } from 'react-redux'

import 'react-multi-carousel/lib/styles.css';
import '../css/example.scss'



const ExampleItem = (item, index) => {
    return <div className='example-item' key={index}>
        <img src={item.img} alt="example"  onClick={() => goToDemo(item.index)} />
        <label>
            {item.title}
        </label>
        <button className="learn-more" onClick={() => goToDemo(item.index)}>
            SEE DEMO
        </button>
    </div>
};

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const goToDemo = (index) => {
    window.open("/demo-page?example=" + index, '_blank');
};

const ExamplePage = (props) => {
    const [tabIndex, setTabIndex] = useState(0);

    const examples = useSelector(state=>state.auth.examples)

    let carouselRef = null;
    return <div className="first-page example-page">
        <div className="header-part header-example">
            <div className="header-title">
                <h2>Stunningly Realistic Virtual Showrooms</h2>
                <h3>Next Generation graphics provide an unrivaled shopping experience for your customers.</h3>
            </div>
        </div>
        <div className="main-content">
            <div>
                <div className='tab-buttons'>

                    <button className={tabIndex === 0 ? 'active' : ''} onClick={() => setTabIndex(0)}>
                        Sheds
                    </button>
                    <button className={tabIndex === 1 ? 'active' : ''} onClick={() => setTabIndex(1)}>
                        Carports
                    </button>
                    <button className={tabIndex === 2 ? 'active' : ''} onClick={() => setTabIndex(2)}>
                        Chicken Coops
                    </button>
                    <button className={tabIndex === 3 ? 'active' : ''} onClick={() => setTabIndex(3)}>
                        Play Sets
                    </button>

                </div>

                <div className='slider-content'>
                    <img src={require('../../assets/example/arrow-img.webp')} onClick={() => carouselRef.previous()} className="slider-arrow left-arrow" alt="left-arrow" />
                    <img src={require('../../assets/example/arrow-img.webp')} onClick={() => carouselRef.next()} className="slider-arrow right-arrow" alt="right-arrow" />
                    <Carousel
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        arrows={false}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={props.deviceType}
                        itemClass="carousel-item-padding-40-px"
                        ref={(el) => (carouselRef = el)}
                    >
                        {
                             examples.filter(ex=>ex.tabIndex === tabIndex).map((item, i) => {
                                return ExampleItem(item, i)
                            })
                        }
                       
                    </Carousel>
                </div>
                <p className='description'>
                    Whether you need a Gambrel, Amish-style, or custom shed, our configurator can meet your needs!  Need help with a garage, carport, coop or play structure model?  We can do that, too!
                </p>
            </div>
        </div>
        <div className='main-content bg-reverse'>
            <div>
                <h5>Don't see your shed or carport style above?</h5>
                <p>
                    Here at Digital Shed Builder, there is no structure too complex for us to tackle. We would love to hear about your business's needs.
                    Contact us for more information about custom projects.
                </p>
                <Link to="/contact-us" className="learn-more learn-more-btn" style={{maxWidth: 142}}>
                    <span style={{ fontSize: 14 }}>CONTACT US</span>
                </Link>
            </div>
        </div>
        <div className='main-content hear-about'>
            <div>
                <h3>
                    I’d like to hear more about getting started!
                </h3>
                <Link to="/contact-us" className="learn-more">
                    <span style={{ fontSize: 14 }}>SCHEDULE A MEETING WITH US</span>
                </Link>
            </div>
        </div>
    </div>
}


export default ExamplePage
