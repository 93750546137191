import React, { Component } from 'react';
import "../css/project-comp.scss";

class MainComp extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {

    }

    handleInput = (e, shed_id, heightSize) => {
        const { heightCosts } = this.props;
        const key = heightSize.scale;
        const value = e.target.value?.replace('$ ', '').replace(/^0+/, '');
        heightCosts[shed_id] = { ...heightCosts[shed_id], [key]: value };
        this.props.handleChangeHeightCosts(heightCosts);
    }

    validateRiskInput = (e) => {
        const input = e.target;
        let value = input.value.replace(/[^-0-9.]/g, '');
        input.value = value;
    }

    toggleMultiplyShow = (e, shed_id) => {
        const { isHeightSizeMultiplyers, isHeightSizePercentages } = this.props;
        if (e.target.checked) {
            isHeightSizeMultiplyers[shed_id] = e.target.checked;
            isHeightSizePercentages[shed_id] = !e.target.checked;
        } else {
            isHeightSizeMultiplyers[shed_id] = e.target.checked;
        }

        const type = 'isHeightSizeMultiplyers';
        this.props.handleMultiplyer(type, isHeightSizeMultiplyers);
    }

    togglePercentageShow = (e, shed_id) => {
        const { isHeightSizeMultiplyers, isHeightSizePercentages } = this.props;
        if (e.target.checked) {
            isHeightSizeMultiplyers[shed_id] = !e.target.checked;
            isHeightSizePercentages[shed_id] = e.target.checked;
        } else {
            isHeightSizePercentages[shed_id] = e.target.checked;
        }

        const type = 'isHeightSizePercentages';
        this.props.handlePercentage(type, isHeightSizePercentages);
    }

    render() {
        const { json_data, project_data, selected_style, heightCosts, isHeightSizeMultiplyers, isHeightSizePercentages } = this.props;

        const shed_id = selected_style || json_data?.load_shed;
        if (!shed_id) return <div></div>

        const loadObj = json_data[shed_id]
        if (!loadObj) return <div></div>

        const heightSizes = loadObj.heightSizes;
        if (!heightSizes || heightSizes.length < 2) return <div></div>;

        return (
            <div className="access-item" >
                <div style={{ marginBottom: '2px' }}>
                    <span className='pricing-title'>Shed Heights</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.toggleMultiplyShow(e, shed_id)}
                        checked={isHeightSizeMultiplyers[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>Multiply Price by SQ FT</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.togglePercentageShow(e, shed_id)}
                        checked={isHeightSizePercentages[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>By Percentage</span>
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="size-item">
                    <div style={{ display: 'flex' }}>
                        <div className='pricing-subtitle-container'>
                            <span className='pricing-subtitle'></span>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
                                heightSizes.map((heightSize, index) =>
                                    <div className='pricing-item-container' key={index}>
                                        <span className='pricing-size-item-title'>{heightSize.scale}</span>
                                        <input
                                            type="text"
                                            className='pricing-item-price'
                                            placeholder=''
                                            value={`${isHeightSizePercentages[shed_id] ? '' : '$ '}${heightCosts[shed_id] ? (heightCosts[shed_id][heightSize.scale] || '0') : '0'}`}
                                            onChange={(e) => this.handleInput(e, shed_id, heightSize)}
                                            onInput={this.validateRiskInput}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MainComp;