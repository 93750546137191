import React, { Component } from 'react';
import "../css/project-comp.scss";

// const menus = [
//     "Front",
//     "Left",
//     "Right",
//     "Back"
// ]
const directions = {
    Front: "positiveX",
    Left: "positiveY",
    Right: "negativeY",
    Back: "negativeX"
}

class MainComp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            includeType: "",
            assetCreditCosts: {}
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.assetCreditCosts !== prevProps.assetCreditCosts || !this.state.updatedFromProps || this.props.radioStatus != this.state.includeType ) {
            this.setState({
                assetCreditCosts: this.props.assetCreditCosts,
                updatedFromProps: true,
                includeType: this.props.radioStatus
            });
        }
    }

    handleRadioChange = (className) => {
        const includeType = className === 'includedByItem';
        this.setState({ includeType });
        this.props.handleChangeRadioBtnStatus(includeType);
    };

    handleInput = (e, shed_id, assetId) => {
        const { assetCosts } = this.props;
        const key = assetId;
        const value = e.target.value?.replace('$ ', '').replace(/^0+/, '');
        assetCosts[shed_id] = { ...assetCosts[shed_id], [key]: value };
        this.props.handleAssetCosts(assetCosts);
    }

    handleGiveFreeInput = (e, shed_id, assetId) => {
        const { assetFreeCosts } = this.props;
        const key = assetId;
        const value = e.target.value?.replace(/^0+/, '');
        assetFreeCosts[shed_id] = { ...assetFreeCosts[shed_id], [key]: value };
        this.props.handleFreeAssetSelect(assetFreeCosts);
    }

    handleGiveCredit = (e, shed_id, asset_type_list) => {
        const value = Number(e.target.value?.replace("$", ""))
        const tempState = {
            assetCreditCosts: {
                ...this.state.assetCreditCosts,
                [asset_type_list]: value
            }
        }
        this.setState({...tempState});
        this.props.updateCreditState({...tempState?.assetCreditCosts})
    }

    validateRiskInput = (e) => {
        const input = e.target;
        let value = input.value.replace(/[^-0-9.]/g, '');
        input.value = value;
    }

    render() {
        const { json_data, project_data, selected_style, assetCosts, assetFreeCosts } = this.props;
        const { includeType, assetCreditCosts } = this.state;
        const shed_id = selected_style || json_data?.load_shed
        if (!shed_id) return <div></div>;

        const loadObj = json_data[shed_id]

        let assets = [];
        let asset_type_lists = [];
        const assetPosSet = loadObj?.assetPosSet;
        if (assetPosSet) {
            assetPosSet.sort((a, b) => {
                if (a.assetIndex > b.assetIndex) {
                    return -1;
                }
                if (a.assetIndex < b.assetIndex) {
                    return 1;
                }
                return 0
            })

            assetPosSet.map((asset_model, index) => {
                const asset = project_data.asset_model.filter(item => item.id === asset_model.asset.id)[0];
                assets.push(asset);
                return true;
            })
        }

        // getting unique assets
        let flags = [], output = [];
        for (let i = 0; i < assets.length; i++) {
            if (flags[assets[i].id]) continue;
            flags[assets[i].id] = true;
            if (assets[i]) {
                output.push(assets[i]);
            }
        }

        assets = output;
        assets.map((asset_type, index) => {
            const asset_type_list = asset_type.asset_type_list;
            
            if (!asset_type_lists.includes(asset_type_list)) {
                asset_type_lists.push(asset_type_list);
            }
        });
        // assets.forEach(asset_type => {
        //     const asset_type_list = asset_type.asset_type_list;
        
        //     if (!asset_type_lists.some(existing_list => existing_list === asset_type_list || asset_type_list.includes(existing_list) || existing_list.includes(asset_type_list))) {
        //         asset_type_lists.push(asset_type_list);
        //     }
        // });
        // console.log("asset_type_lists============>", asset_type_lists);
        
        if (assets.length < 1) return <div></div>;

        return (
            <div className="access-item" >
                <div style={{ marginBottom: '2px' }}>
                    <span className='pricing-title'>Accessories</span>
                    <div className='pricing-title-bottom'></div>
                </div>
                <div >
                    <div style={{padding: '10px'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '370px' }}>
                            <div style={{ display: 'flex', alignItems: 'center',justifyContent: 'space-between' , width: '160px' }}>
                                <span style={{fontSize: 'large', color: '#26455d'}}>Included By Item:</span>
                                <input
                                    type="radio"
                                    className='includedByItem'
                                    style={{width: '20px', height: '20px'}}
                                    checked={includeType}
                                    onChange={() => this.handleRadioChange('includedByItem')}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center',justifyContent: 'space-between' , width: '168px' }}>
                                <span style={{fontSize: 'large', color: '#26455d'}}>Included By Value:</span>
                                <input
                                    type="radio"
                                    className='includeByValue'
                                    style={{width: '20px', height: '20px'}}
                                    checked={!includeType}
                                    onChange={() => this.handleRadioChange('includeByValue')}
                                />
                            </div>
                        </div>
                        <div style={{ display: includeType ? 'none' : 'block', padding: '13px' }}>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {
                                    asset_type_lists.map((asset_type_list, j) =>
                                        <div key={j} style={{width: '121px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '12px'}}>
                                            <span style={{color: '#26455d', fontWeight: '520'}}>{asset_type_list}</span>
                                            <input
                                                type="text"
                                                className='pricing-item-price'
                                                style={{margin: '0px'}}
                                                value={'$' + (assetCreditCosts?.[asset_type_list] || 0)}
                                                onChange={(e) => this.handleGiveCredit(e, shed_id, asset_type_list)}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="select-panel">
                    {
                        assets.map((asset, i) =>
                            <div key={i} className='pricing-item-container tooltip-div'>
                                <div
                                    className="package-item"
                                    style={{ backgroundImage: (asset && asset.asset_img) ? `url(${process.env.REACT_APP_SERVER_URL + asset.asset_img})` : "url('/static/imgs/icon-upload.png'" }}
                                >
                                </div>
                                <span className="tooltip-content">{asset.type_name}</span>
                                <input
                                    type="text"
                                    className='pricing-item-price'
                                    placeholder=''
                                    value={`$ ${assetCosts[shed_id] ? (assetCosts[shed_id][asset.id] || '0') : '0'}`}
                                    onChange={(e) => this.handleInput(e, shed_id, asset.id)}
                                    onInput={this.validateRiskInput}
                                />
                                <div style={{ display: includeType ? 'block' : 'none' }}>
                                    <span style={{color: '#26455d', fontWeight: '600'}}>Included:</span>
                                    <input
                                        type='text'
                                        className='pricing-item-price free-asset-const'
                                        value={`${assetFreeCosts[shed_id] ? (assetFreeCosts[shed_id][asset.id] || '0') : '0'}`}
                                        onChange={(e) => this.handleGiveFreeInput(e, shed_id, asset.id)}
                                        onInput={this.validateRiskInput}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default MainComp;