import React from 'react'
import { Link } from 'react-router-dom'
// import ReactPlayer from 'react-player'

import videoFile from '../../assets/first-page/shedtest.mp4'
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll'

class PageComp extends React.Component {
    componentDidMount() {

        const targetObj = document.getElementById("DSB-video");
        Events.scrollEvent.register('begin', function (to, element) {
        });
        Events.scrollEvent.register('end', function (to, element) {
            console.log("target obj : ", targetObj.offsetTop, window.scrollY)
            if (Math.abs(targetObj.offsetTop - window.scrollY) > 3)
                scroll.scrollTo(targetObj.offsetTop, {
                    duration: 100,
                    delay: 0,
                    smooth: false
                });
        });
        scrollSpy.update();

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (targetObj && urlParams.get('anchor'))
            scroll.scrollTo(targetObj.offsetTop, {
                duration: 500,
                delay: 0,
                smooth: true
            });
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return <div className="first-page">
            <div className="header-part header-firstpage">
                <div className="header-title">
                    <h2>Buying is A <b>Visual Experience</b></h2>
                    <h3 >Get an incredible online experience for YOUR website. Convert more shoppers into buyers.</h3>
                    <br />
                    <Link to="/contact-us" className="schedule-call">
                        <span >SCHEDULE A CALL</span>
                    </Link>
                </div>
            </div>

            <div className="main-content p-0" id='DSB-video'>
                <div className="config-content">
                    <div className="threed-config">

                        <div>
                            <Link to="/example">
                                <span>
                                    <b>3D Configurator</b> for All of Your <b>Sheds</b>
                                </span>
                            </Link>
                        </div>

                        <div className='video-window'>
                            <video width={"100%"} autoPlay loop muted playsInline src={videoFile}>
                            </video>
                        </div>
                    </div>

                    <p className="learn-more-p btn-get-started">
                        <Link to="/demo-page" className="learn-more">
                            <span >TRY ME</span>
                        </Link>
                    </p>

                </div>
            </div>

            <div className="main-content" style={{ paddingBottom: 27 }}>
                <div className="config-content">
                    <div className="shed-ad">
                        <div className="half-side">
                            <p className="side-title">
                                The future of <b>Selling Your Sheds Online</b> is Here
                            </p>
                            <p style={{ fontSize: 16, lineHeight: 1.3, fontWeight: 500 }}>
                                Our configurator is the perfect digital show lot. We use cutting-edge technology to deliver stunning visual quality with the flexibility to support all custom designs.
                                <br /><br />
                                Give your customers a fun and intuitive shopping experience that allows them to design and order their dream shed from the comfort of home.  We take care of everything for you.  Seamless integration with your website or ecommerce platform makes the whole process headache free.
                            </p>
                            <br></br>
                            <Link to="contact-us" className="learn-more learn-more-btn">
                                <span style={{ fontSize: 14 }}>Learn More</span>
                            </Link>
                        </div>

                        <div className="half-side">
                            <img src={require("../../assets/home/DevicesGraphic.webp")} alt="screens" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-content online-sales">
                <p className="sales-title"><span >We <b>Empower</b> Your <b>Online Sales</b></span></p>
                <div className="brandings">

                    <div className="branding-item">
                        <p className="branding-header">
                            <img src={require('../../assets/home/branding.webp')} alt="branding" />
                            <b>Branding</b>
                        </p>
                        <p style={{ fontSize: 14, LineHeight: 1.3 }}>
                            Buying is a visual experience, and we recognize the importance of that. 
                             We can customize the UI and look of the configurator to match your brand image. 
                        </p>
                    </div>

                    <div className="branding-item">
                        <p className="branding-header">
                            <img src={require('../../assets/home/partnership.webp')} alt="branding" />
                            <b>Partnerships</b>
                        </p>
                        <p style={{ fontSize: 14, LineHeight: 1.3 }}>
                            We work directly with you and take care of all the tech stuff, including integration. 
                             Getting a beatiful configurator on your website has never been easier.
                        </p>
                    </div>

                </div>
                <p className="learn-more-p">
                    <Link to="contact-us" className="learn-more">
                        <span style={{ fontSize: 14 }}>Learn More</span>
                    </Link>
                </p>
            </div>

            <div className="main-content online-config">
                <div className="config-content">

                    <div className="half-content">
                        <p style={{ fontSize: 27 }}>
                            Having an <b>Online Configurator</b> drives <b>Sales</b>
                        </p>
                    </div>

                    <div className="half-content" style={{ display: "flex", justifyContent: "space-around" }}>

                        <div className="mark-content">
                            <p style={{ fontSize: 72, fontWeight: 510, marginBottom: 0 }}>10<span style={{ fontSize: 44, fontWeight: 400, LineHeight: 1.3 }}>x</span></p>
                            <p style={{ fontSize: 14, LineHeight: 1 }}>Reach a larger audience and generate more hot leads online.
                            </p>
                        </div>

                        <div className="mark-content">
                            <p style={{ fontSize: 72, fontWeight: 400, LineHeight: 1.3, marginBottom: 0 }}>+<span style={{ fontSize: 72, fontWeight: 510, LineHeight: 1.3 }}>40</span><span style={{ fontSize: 44, fontWeight: 400, LineHeight: 1.3 }}>%</span></p>
                            <p style={{ fontSize: 14, LineHeight: 1 }}>Websites with configurators drive more sales on average then those without.
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="main-content" style={{ paddingTop: 21 }}>
                <div className="config-content">
                    <p style={{ fontSize: 20, fontWeight: 600, lineHeight: 1 }}>Why Use an Online Configurator?</p>
                    <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500 }}>
                        A confused mind doesn't buy, and there's more uncertainty online than in person. Think for a moment about your customer's journey. If they're shopping at a physical location, they may visit one or two stores and select from a finite supply of options. But online, the possibilities are virtually infinite.
                    </p>
                    <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500 }}>
                        How can you help them be confident that your product is the one for them and reduce or eliminate the risk in their decision-making process?
                    </p>
                    <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500 }}>
                        3D product configuration works to show customers all of their options. They can view color changes and evaluate how different features look in real time. No other solution recreates an in-person shopping experience quite like the Digital Shed Builder configurator.
                    </p>
                </div>
            </div>

            <div className="main-content" style={{ paddingTop: 21 }}>
                <div className="config-content">
                    <p style={{ fontSize: 20, fontWeight: 600, lineHeight: 1 }}>Where Can You Use Your Online Configurator?</p>

                    <p><span><b>Integration: </b> </span><span style={{ fontSize: 14 }}>We support Shopify, Magento, WooCommerce, Sitecore, and Salesforce Commerce Cloud. Turn your ecommerce store into a virtual showroom.</span>
                    </p>

                    <p><span><b>Customization: </b></span><span style={{ fontSize: 14 }}> Provide a 360-degree product viewer that is native to your brand. Our 3D product configurator is fully customizable for all of your models.</span>
                    </p>

                    <p style={{ lineHeight: 1.3 }}><span><b>Results: </b></span><span style={{ fontSize: 14 }}> Our tools allow you to provide an unmatched shopping experience, with some of our customers seeing a 40% increase in sales. By personalizing the product, you can increase your selling price and promote premium features.</span>
                    </p>
                </div>
            </div>



            <div className="main-content" style={{ paddingTop: 21 }}>
                <div className="config-content">
                    <p style={{ fontSize: 20, fontWeight: 600, lineHeight: 1 }}>What Digital Shed Builder Does for You</p>

                    <div className="somethings">
                        <div className="something-item">
                            <p style={{ fontSize: 14, fontWeight: 600, lineHeight: 1 }}>Get More Sales</p>
                            <p style={{ fontSize: 14, lineHeight: 1.3 }}>
                                Reach a larger audience and get more sales by allowing your customers to design and purchase the perfect shed from the comfort of their home.
                            </p>
                        </div>

                        <div className="something-item">
                            <p style={{ fontSize: 14, fontWeight: 600, lineHeight: 1 }}>Capture More Leads</p>
                            <p style={{ fontSize: 14, lineHeight: 1.3 }}>
                                We provide the option to include email capture within the configurator and build more leads online.
                            </p>
                        </div>

                        <div className="something-item">
                            <p style={{ fontSize: 14, fontWeight: 600, lineHeight: 1 }}>Look The Best</p>
                            <p style={{ fontSize: 14, lineHeight: 1.3 }}>
                                We are happy to go head to head with any 3D Shed Configurator out there.  Nothing looks as good as the cutting edge technology that we have pioneered.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="shopify">
                <h3>Works on most playforms including:</h3>
                <img src={require('../../assets/home/shopify.webp')} alt="shopify" />
                <img src={require('../../assets/home/woo.webp')} alt="woo" />
                <img src={require('../../assets/home/wordpress.webp')} alt="wordpress" />
                <img src={require('../../assets/home/magento.webp')} alt="magento" />
            </div>
            <div className="main-content getting-config">
                <div className="config-content">
                    <b><span style={{ fontSize: 20 }}>Getting Your Very Own Configurator is Simple. </span></b>

                    <Link to="contact-us" className="learn-more">
                        <span style={{ fontSize: 14 }}>Learn More</span>
                    </Link>
                </div>
            </div>
        </div>;
    }
}
export default PageComp
