import axios from "axios";
import * as actionTypes from "./actionTypes";



export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const authUser = user => {
  return {
    type: actionTypes.GET_AUTH,
    user
  };
};

export const logout = () => {
  localStorage.removeItem("authUser");
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("showAnalyticsSection");
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

const authProjects = arr => {
  return { 
    type: actionTypes.GET_AUTH_PROJECTS,
    arr
  }
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const authLogin = (email, password, callback) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/customer_signin/", {
        email,
        password
      })
      .then(res => {

        const data = res.data;
        const user = data.data.user;
        localStorage.setItem("authUser", JSON.stringify(user));
        localStorage.setItem("token", user.id);

        const expirationDate = new Date(new Date().getTime() + 7200 * 1000);
        localStorage.setItem("expirationDate", expirationDate);

        dispatch(authUser(user))
        dispatch(checkAuthTimeout(7200));
        // setTimeout(() => {
        console.log("authed user : ", user);
        if(user.subscribe_status){
          window.location.href = "/member-page";
        }else{
          window.location.href = `/payment?customer=${user.id}&amount=${user.subscribe_amount.value}&step=1`;
        }
        // }, 300);
      })
      .catch(err => {
        console.log("auth failed : ", err.response, err.message)
      //  dispatch(errorOpen(err.response.data.message))
        // dispatch(authFail(err));
        callback(err)
      });
  };
};

export const getAuthProjects = (user_id, callback) => {

  return dispatch => {
    axios({
      method: "post",
      url: process.env.REACT_APP_SERVER_URL + "/get_projects_by_userid/" + user_id,
    })
      .then(function (res) {
        //handle success
        dispatch(authProjects(res.data.data.result))
        setTimeout(() => {
          callback(true);
        }, 100);
      })
      .catch(function () {
        //handle error
        dispatch(authProjects([]))
        setTimeout(() => {
          callback(false);
        }, 100);
      });

  }

};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(logout());
    } else {

      let expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {

        axios({
          method: "get",
          url: process.env.REACT_APP_SERVER_URL + "/get_customer_by_id/" + token,
        })
          .then(function (res) 
          {
            const user = res.data.data.result;
            console.log("reload user : ", user);
            localStorage.setItem("authUser", JSON.stringify(user));
            expirationDate = new Date(new Date().getTime() + 7200 * 1000);
            localStorage.setItem("expirationDate", expirationDate);
            dispatch(authSuccess(token));
            dispatch(authUser(user))
            dispatch(checkAuthTimeout(7200));
          })
          .catch(function () {
             dispatch(logout());           
          });
      }
    }
  };
};
