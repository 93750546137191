import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { createStore, compose, applyMiddleware, combineReducers } from "redux";

import { Provider } from "react-redux";
import thunk from "redux-thunk";

import authReducer from "./store/reducers/auth";
import dlgReducer from "./store/reducers/dlgs";
import alertReducer from './store/reducers/alerts';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const composeEnhances =
  typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const rootReducer = combineReducers({
  auth: authReducer,
  dlgs: dlgReducer,
  alerts: alertReducer
});

const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));



root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
