import * as actionTypes from "./actionTypes";

export const successOpen = (message) => {
  return {
    type: actionTypes.SUCCESSALERT_OPEN,
    data: {
      types: 'success',
      message,
      open: true
    }
  };
};

export const successClose = () => {
  return {
    type: actionTypes.SUCCESSALERT_CLOSE
  };
};


export const errorOpen = (message) => {
  return {
    type: actionTypes.ERRORALERT_OPEN,
    data: {
      types: 'error',
      message,
      open: true
    }
  };
};


export const errorClose = () => {
  return {
    type: actionTypes.ERRORALERT_CLOSE
  };
};

