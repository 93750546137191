import React, { useState, useEffect } from 'react'
// import {Link} from 'react-router-dom'


import { useDispatch } from "react-redux";
import { authLogin } from "../../store/actions/auth";
import { successOpen } from '../../store/actions/alerts'
import { FaTimes } from 'react-icons/fa';


import "../css/sign-in.scss"

const Comp = () => {

    // const [user, setUser] = useState({ firstname: "", lastname: "" });
    const [email, setEmail] = useState("")
    const [forgot, setForgot] = useState(false)
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState(false)
    const [forgotPwdError, setForgotPwdError] = useState(false)

    const dispatch = useDispatch()
    // const authUser = useSelector((state) => state.auth.user)
    useEffect(() => {
        // GET request using fetch inside useEffect React hook
    }, []);

    const submitLogin = () => {
        dispatch(authLogin(email, password, (err) => {
            console.log("error : ", err);
            setLoginError(true);
        }))
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const recoverPassword = () => {
        const data = {
            email
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        fetch(`${process.env.REACT_APP_SERVER_URL}/customer_forgot_pwd/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    console.log("authed customer :", data)
                    dispatch(successOpen(data.data.message))
                    setTimeout(() => {
                        window.location.href = "/sign-in"
                    }, 3000);
                }

            })
            .catch(error => {
                // alert(error.response.data.message);
                //dispatch(errorOpen(error.response.data.message))
                setForgotPwdError(true);
            })

    }


    return <div className="signin-comp">
        <div className='gap-head'></div>
        <div className='title-comp'>
            <label>
                {forgot ? "Recover password" : "Log In To Your Account"}
            </label>
        </div>
        <div className="gradient-line"></div>

        {forgot ? <div className='price-contact'>
            <br />
            <h3>
                You will get the link to activate your account:
            </h3>
            <div className='account-info'>
                {forgotPwdError && <div className='error-dialog'>
                    <p>
                        Sorry! <br/> Your forgot password process has been failed. Please try again.
                    </p>
                    <span onClick={() => setForgotPwdError(false)}>
                        <FaTimes />
                    </span>
                </div>}

                <p>
                    User Email :
                </p>

                <input type='text' value={email} onChange={onChangeEmail} placeholder='Email' />
                <div className='button-div'>
                    <p>
                        &nbsp;
                    </p>
                    <button onClick={() => recoverPassword()}>
                        Recover password
                    </button>
                </div>
            </div>

        </div> :

            <div className='price-contact'>
                <br />
                <h3>
                    Account Info:
                </h3>
                <div className='account-info'>
                    {loginError && <div className='error-dialog'>
                        <p>
                            Please enter a correct username and password. Note that both fields may be case-sensitive.
                        </p>
                        <span onClick={() => setLoginError(false)}>
                            <FaTimes />
                        </span>

                    </div>}
                    <p>
                        User Email :
                    </p>
                    <input type='text' value={email} onChange={onChangeEmail} placeholder='Email' />
                    <p>
                        User Password :
                    </p>
                    <input type='password' value={password} placeholder="Password" onChange={onChangePassword} />
                    <div className='button-div'>
                        <p onClick={() => setForgot(true)}>
                            Forgot your password?
                        </p>
                        <button onClick={() => submitLogin()}>
                            LOG IN
                        </button>
                    </div>
                </div>
            </div>
        }
    </div>
}
export default Comp
