import React from 'react'
import "../css/pricing.scss"


const Comp = () =>
    <div className="pricing-comp">
        <div className="header-part header-example terms-service">
            <div className="header-title">
                <h2>Terms and Conditions</h2>
            </div>
        </div>

        <div className='price-contact terms-service'>
            <br />
            <br />

            <h4>
                Welcome to Digital Shed Builder!
            </h4>
            <p>
                These terms and conditions outline the rules and regulations for the use of Digital Shed Builder's Website, located at https://digitalshedbuilder.com.
            </p>
            <br />

            <p>
                By accessing this website we assume you accept these terms and conditions. Do not continue to use Digital Shed Builder if you do not agree to take all of the terms and conditions stated on this page.
            </p>

            <br />
            <p>

                The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of the United States of America. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
            </p>
            <br />

            <h5>
                Cookies
            </h5>
            <p>
                We employ the use of cookies. By accessing Digital Shed Builder, you agreed to use cookies in agreement with the Digital Shed Builder's Privacy Policy.
            </p>
            <br />
            <p>
                Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
            </p>
            <p>License</p>
            <p>
                Unless otherwise stated, Digital Shed Builder and/or its licensors own the intellectual property rights for all material on Digital Shed Builder. All intellectual property rights are reserved. You may access this from Digital Shed Builder for your own personal use subjected to restrictions set in these terms and conditions.
            </p>
            <br />
            <h5>
                You must not:
            </h5>

            <p>
                Republish material from Digital Shed Builder
            </p>
            <p>Sell, rent or sub-license material from Digital Shed Builder</p>
            <p>Reproduce, duplicate or copy material from Digital Shed Builder</p>
            <p>Redistribute content from Digital Shed Builder</p>
            <br />
            <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.</p>
            <br />
            <p>
                Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Digital Shed Builder does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Digital Shed Builder,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Digital Shed Builder shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
            </p>
            <br />
            <p>
                Digital Shed Builder reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
            </p>
            <br />
            <p>
                You warrant and represent that:
            </p>
            <p>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</p>
            <p>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</p>
            <p>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</p>
            <p>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</p>
            <br />
            <p>You hereby grant Digital Shed Builder a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
            <p>Hyperlinking to our Content</p>
            <p>The following organizations may link to our Website without prior written approval:</p>
            <p>Government agencies;</p>
            <p>Search engines;</p>
            <p>News organizations;</p>
            <p>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
                System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.</p>
            <br />
            <p>
                These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.
            </p>
            <br />

            <p>
                We may consider and approve other link requests from the following types of organizations:
            </p>

            <p>commonly-known consumer and/or business information sources;</p>
            <p>dot.com community sites;</p>
            <p>associations or other groups representing charities;</p>
            <p>online directory distributors;</p>
            <p>internet portals;</p>
            <p>accounting, law and consulting firms; and</p>
            <p>educational institutions and trade associations.</p>
            <br />
            <p>
                We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Digital Shed Builder; and (d) the link is in the context of general resource information.
            </p>
            <br />
            <p>
                These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.
            </p>
            <br />
            <p>
                If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Digital Shed Builder. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
            </p>
            <br />
            <p>
                Approved organizations may hyperlink to our Website as follows:
            </p>
            <p>
                By use of our corporate name; or
            </p>
            <p>
                By use of the uniform resource locator being linked to; or
            </p>
            <p>
                By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.
            </p>
            <br />
            <p>
                No use of Digital Shed Builder's logo or other artwork will be allowed for linking absent a trademark license agreement.
            </p>
            <br />
            <h5>
                iFrames
            </h5>
            <p>
                Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
            </p>
            <br />

            <h5>
                Content Liability
            </h5>
            <p>
                We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
            </p>
            <br />
            <h5>
                Your Privacy
            </h5>
            <p>Please read Privacy Policy at https://digitalshedbuilder.com/privacy-policy</p>
            <p>Reservation of Rights</p>
            <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
            <p>Removal of links from our website</p>
            <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
            <br />
            <p>
                We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
            </p>
            <br />

            <h5>
                Disclaimer
            </h5>
            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
            <p>limit or exclude our or your liability for death or personal injury;</p>
            <p>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</p>
            <p>limit any of our or your liabilities in any way that is not permitted under applicable law; or</p>
            <p>exclude any of our or your liabilities that may not be excluded under applicable law.</p>
            <br />
            <p>
                The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
            </p>
            <br />
            <p>
                As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
            </p>
            <br />
            <p>
                Generated using Terms and Conditions Generator
            </p>
            <br />
            <h5>
                Refund/Cancellation Policy
            </h5>
            <p>
                Due to the nature of digital content, Digital Shed Builder does not offer refunds.
            </p>
            <br />
            <p>
                To cancel your account, please reach out to us at support@digitalshedbuilder.com.  We will disable your payment method so that at the end of the current pay period, you will no longer be billed.  You will have access to your digital content until the end of the current pay period.
            </p>
            <br />
            <h5>
                End User License Agreement (“EULA”)
            </h5>
            <br />
            <p>
                By accepting this EULA or by downloading or using the software you signify that you have read and agree to all the terms of this license agreement.
            </p>
            <br />
            <h5>
                Preamble
            </h5>
            <br />
            <p>
                By agreeing to this EULA, you the user (“User”) hereby indicates User’s complete and unconditional acceptance of all the terms and conditions of this EULA. This EULA constitutes the complete agreement between User and Digital Shed Builder with regard to User’s access, download, and/or use of the software and all content contained therein (individually and collectively, the “Content”), and this EULA supersedes and replaces any other agreement that was or will be presented at the time of Content installation.
            </p>
            <br />
            <h5>
                General License Agreement
            </h5>
            <br />
            <p>
                1. Content License. Digital Shed Builder grants to User and User hereby accepts, subject to the
                limitations and obligations of this EULA, a personal, non-exclusive, non-transferable license to
                use the Content.
            </p>
            <br />
            <p>
                2. Open Source and Third Party Components. The Content contains or may contain certain
                components which are used and/or published under open source licenses. By accepting this
                EULA, User agrees to comply with all applicable third party license agreements.
            </p>
            <br />
            <p>
                3. Title and Ownership. The Content contains copyrighted and/or proprietary information
                protected by the laws of the United States and/or international laws and treaties. Digital Shed Builder and its third party licensors retain all rights in, title to, and ownership of the Content. Digital Shed Builder gives no rights or warranties with regard to the use of any objects, names,
                trademarks, service marks, or works of authorship depicted in any Content, and User is solely
                responsible for separately obtaining all such necessary rights or consents that may be required
                for any particular use of objects, names, trademarks, service marks or works of authorship. Digital Shed Builder reserves all rights not expressly granted to the User in this EULA.
            </p>
            <br />
            <p>
                4. Restrictions on Copying. The Content is provided for User use only. User does not have the
                right to provide the Content to others in any form or on any media except as set forth in this
                EULA. The Content may be copied in whole or in part for User’s use only. Specifically, User
                may copy the Content onto the storage device of an unlimited number of computers owned or
                controlled by User. Each individual or entity must obtain his or her own license to use the
                Content.
            </p>
            <br />
            <p>
                5. Terms of Use. Subject to the terms and conditions of this EULA, User may (i) access, use, copy and modify the textures in the Content library in the creation and presentation of animations, renderings, and other permitted uses and works, (ii) incorporate images derived by User from the textures in the Content library in User’s animations, renderings, and other permitted uses and works, and (iii) publish, market, distribute, transfer, sell or sublicense User’s animations, renderings and other permitted uses and works; provided that User may not in any case publish, market, distribute, transfer, sell or sublicense any textures, renderings, software applications, data or any other product from which the Content, or any part thereof, or any substantially similar version of the Content can be separately exported, extracted or de-compiled into any re-distributable form or format. All other rights with respect to the Content and its use are reserved by Digital Shed Builder and its licensors.
            </p>
            <br />
            <p>
                6. Other Restrictions. This EULA is User’s proof of license to exercise the rights granted herein
                and may be printed and retained by User. User shall not give, sell, rent, lease, sublicense, or
                otherwise transfer or distribute any Content on a temporary or permanent basis without the prior
                written consent of Digital Shed Builder. User may not reverse engineer, de-compile, disassemble, or create derivative works from the Content except as set forth in Section 5 above.
            </p>
            <br />
            <p>
                7. Protection and Security. User agrees that the Content is the property of and proprietary to Digital Shed Builder and its licensors, and further agrees to protect the Content and all parts thereof from unauthorized disclosure and use by User’s agents, employees, associates, family members, customers, or any other third party. User shall be exclusively responsible to ensure the selection, supervision, management, control, and use of the Content conforms to all terms of this EULA.
            </p>
            <br />
            <p>
                8. Export Restrictions. The Content may be subject to the export controls of the United States
                Departments of State and Commerce and User agrees to fully comply with all applicable United
                States export regulations governing export, destination, ultimate end user, and other restrictions
                relating to the Content.
            </p>
            <br />
            <p>
                9. United States Government Restrictions Rights. If User is part of any agency, department, or
                other entity of the United States Government (the “Government”), the use, duplication,
                reproduction, release, modification, disclosure or transfer of the Content is restricted in
                accordance with the Federal Acquisition Regulations as applied to civilian agencies and the
                Defense Federal Acquisition Regulation Supplement as applied to military agencies. The
                Content is a “commercial item” and “commercial computer software”. In accordance with such
                provisions, any use of the Content by the Government shall be governed solely by the terms of
                this EULA.
            </p>
            <br />
            <p>
                10. Patent, Copyright, and Trade Secret Indemnity. User agrees to indemnify The Texture
                Mill and hold Digital Shed Builder harmless against all liability resulting from or related to any
                claim that (i) any content or work created by User, or (ii) User’s use of the Content, infringes
                any third-party patent right, copyright, or other intellectual property right, or misappropriates, or
                misuses any trade secret or other proprietary right, or any portion thereof, of any third party.
            </p>
            <br />
            <p>
                11. Infringement Warranty. Digital Shed Builder warrants to the User that, to the best of its
                knowledge, the digital data comprising the Content developed and owned by Digital Shed Builder, does not infringe the rights, including patent, copyright, and trade secret rights, of any third party, nor was such digital data unlawfully copied or misappropriated from digital data owned by any third party; provided, however, that Digital Shed Builder makes no representation or warranty with respect to infringement of any third party’s rights in any image, trademarks,
                works of authorship, or object depicted by such Content or in any Content developed by any of
                Digital Shed Builder’s licensors.
            </p>
            <br />
            <p>
                12. Limited Warranty. Digital Shed Builder warrants to the User that, to the best of its
                knowledge, the digital data comprising the Content developed and owned by Digital Shed Builder, does not infringe the rights, including patent, copyright and trade secret rights, of any third party, nor was such digital data unlawfully copied or misappropriated from digital data owned by any third party; provided, however, that Digital Shed Builder makes no representation or warranty with respect to infringement of any third party’s rights in any image, trademarks,
                works of authorship or object depicted by such Content or in any Content developed by any of
                Digital Shed Builder’s licensors.
            </p>
            <br />
            <p>
                13. No Other Warranties. Except as expressly provided above, the Content and all related
                documentation are provided on an “as is” and “as available” basis, without warranty of any
                kind, and Digital Shed Builder hereby disclaims all other warranties, express, implied or statutory, including, but not limited to, any implied warranties of merchantability, non-infringement and fitness for a particular purpose, and any warranties arising from usage of trade or course of dealing or performance. In no event shall Digital Shed Builder or its dealers, distributors, officers, agents, employees, or suppliers be liable for any indirect, incidental, special, punitive, or consequential damages whatsoever, whether based on contract, tort, warranty, or other legal or equitable grounds, including, without limitation, damages for loss of business profits, business interruption, loss of business information, or other pecuniary loss, arising out of the use of or inability to use the Content, even if Digital Shed Builder has been advised of the possibility of such damages. In addition, in no event shall Digital Shed Builder’ s cumulative liability hereunder exceed the license fees paid by user to Digital Shed Builder for licensing the content. Because some states do not allow the exclusion or limitation of liability for consequential or incidental damages, the above limitation may not apply to User.
            </p>
            <br />
            <p>14. No Support. Nothing in this EULA shall obligate Digital Shed Builder to provide any
                support for the software and/or Content. Digital Shed Builder may, but shall be under no obligation to, correct any defects in the software and/or Content and/or provide updates to User of the software and/or Content. User shall promptly report to Digital Shed Builder any defects User finds in the software and/or Content to aid Digital Shed Builder in creating improved revisions of such Items.
            </p>
            <br />
            <p>
                15. No Liability for Hardware. User assumes complete responsibility for all hardware used
                in conjunction with the Content. Digital Shed Builder shall not be responsible in any way for the
                non-performance or malfunction of any hardware.
            </p>
            <br />
            <p>16. Remedies.</p>
            <br />
            <p>1. Injunctive Remedies. The remedies provided herein shall not be deemed exclusive, but shall be cumulative and shall be in addition to all other remedies provided by law and
                equity. No delay or omission in the exercise of any remedy herein provided or otherwise
                available to Digital Shed Builder shall impair or effect Digital Shed Builder’s right to exercise the same. An extension of indulgence or forbearance (which must be in writing) shall not otherwise alter or effect Digital Shed Builder’s rights or obligations, nor be deemed to be a waiver thereof. The parties hereto agree that breach of any provisions of non-disclosure, secrecy, confidentiality, copying, use, protection, and security in this EULA by User will cause immediate and irreparable damage and injury to Digital Shed Builder.</p>
            <br />
            <p>
                Each of the parties confirms that damages at law may be an inadequate remedy for
                breach or threatened breach of any such provisions. The parties agree that in such event Digital Shed Builder shall be entitled by right to an injunction restraining the User from violating any of said provisions. User hereby acknowledges that Digital Shed Builder has disclosed or will disclose to User valuable proprietary data set products, which are new and unique and give Digital Shed Builder a competitive advantage in the marketplace; that Digital Shed Builder intends to use such information to expand its business throughout the world; and that a violation of any of the provisions of this EULA is material and important and Digital Shed Builder shall, in addition to all other rights and remedies available hereunder, at law or otherwise, be entitled to a temporary restraining order and an injunction to be issued by any court of competent jurisdiction enjoining and
                restraining User from committing any violation of said provisions, and User shall
                consent to the issuance of such injunction. User acknowledges that the remedies
                provided for in this EULA are not injurious to or violative of any public interest or
                policy, and will not create a hardship greater than is necessary to protect the interests of
                Digital Shed Builder.
            </p>
            <br />
            <p>
                2. Arbitration Remedies. Any dispute arising out of or relating to this EULA, or a breach
                thereof, shall be determined by arbitration administered by the American Arbitration
                Association in accordance with its International Arbitration Rules. Any such arbitration
                shall take place exclusively in San Francisco, California and the language of the
                arbitration shall be English. The arbitration shall be conducted by a single arbitrator and
                the decision of the arbitrator shall be final, conclusive and binding on the parties.
                Judgment may be entered on the arbitrator’s decision in any court having jurisdiction.
                Digital Shed Builder and User shall each pay one-half of the costs and expenses of any
                arbitration, and the substantially non-prevailing party, as determined by outcome of the
                arbitration, shall be liable for both parties’ attorneys’ fees and costs. Notwithstanding the
                foregoing, either party may apply to any court of competent jurisdiction for injunctive or
                other equitable relief without breach of this arbitration provision.
            </p>
            <br />
            <p>
                17. General Provisions.
                1. Costs and Expenses of Enforcement. In the event of the failure of either party hereto to comply with any provisions of this EULA, the defaulting party shall pay any and all
                costs and expenses, including reasonable attorneys’ fees arising out of or resulting from
                such default (including any incurred in connection with any appeal), incurred by the
                injured party in enforcing its rights and remedies, whether such right or remedy is
                pursued by filing a lawsuit or otherwise.
            </p>
            <br />
            <p>
                2. Entire Agreement. This EULA is the entire agreement between the parties hereto with
                respect to the subject matter hereof, and it supersedes all prior agreements or
                understandings regarding its subject matter.
            </p>
            <br />
            <p>
                3. Governing Law. This EULA and the relationship of Digital Shed Builder and User is
                governed by the laws of the State of California, without regard to its conflict of law
                provisions. In addition, this EULA will not be governed or interpreted in any way by
                referring to any law based on the Uniform Computer Information Transactions Act
                (UCITA), and the United Nations Convention on Contracts for the International Sale of
                Goods is hereby excluded.
            </p>
            <br />
            <p>
                4. Successors and Assigns. The language of this EULA shall be construed as a whole,
                according to its fair meaning and intent, and not strictly for or against either party,
                regardless of who drafted or was principally responsible for drafting this EULA or any
                specific term or conditions hereof. This EULA shall bind and inure to the benefit of the
                parties and their successors and permitted assigns.
            </p>
            <br />
            <p>
                5. Relationship of the Parties. Both parties are acting as independent contractors with
                respect to the activities hereunder. Nothing in this EULA shall be deemed to create any
                type of agency, joint venture, or partnership relationship between the parties.
            </p>
            <br />
            <p>
                6. Further Information. Should User have any questions concerning any of the provisions
                of this EULA, or if User desires to contact Digital Shed Builder, please write to:
                support@digitalshedbuilder.com.
            </p>
            <br />
            <p>
                7. Trademark and Copyright. All Digital Shed Builder products are trademarks or registered trademarks of Digital Shed Builder LLC. All other brand and product names are trademarks or registered trademarks of their respective holders.
            </p>
            <br />
            <p>
                8. Severability. If any part of this EULA is found to be unenforceable, the other provisions
                shall remain fully valid and enforceable. It is the intention and agreement of the parties
                that all of the terms and conditions hereof be enforced to the fullest extent permitted by
                Law.
            </p>
            <br />
            <p>
                9. Assignment. This EULA, and any rights and licenses granted hereunder, may not be
                transferred or assigned by User, but may be assigned by Digital Shed Builder without
                Restriction.
            </p>
            <br />
            <p>
                10. Statute of Limitations. Regardless of any statute or law to the contrary, any claim
                or cause of action arising out of or related to this EULA or User’s use of the Content
                must be filed within one (1) year after such claim or cause of action arose or be forever
                Barred.
            </p>
            <br />
            <p>
                11. Amendment. Digital Shed Builder reserves the right to amend, change, or revise this
                EULA at any time without notice to User.
            </p>

            <br />
        </div>
    </div>

export default Comp
