import React, { Component } from 'react';
import "../css/project-comp.scss";

class MainComp extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {

    }

    handleSelectStyle = (shed) => {
        const selected_style = shed.id;
        this.props.handleChangeStyle(selected_style);
    }

    handleCostChange = (e) => {
        console.log('handleCostChange: ', e.target.value);
    }

    render() {
        const { json_data, project_data, selected_style } = this.props
        const shed_id = selected_style || json_data?.load_shed
        if (!shed_id) return <div></div>

        let styleItems = []
        if (json_data?.selected_styles) {
            Object.keys(json_data?.selected_styles).map(style_key => {
                if (json_data?.selected_styles[style_key]) {
                    const styleItem = project_data?.shed_model.filter(model => model.id === style_key)[0]
                    styleItems.push(styleItem)
                }
                return true;
            })
        }

        if (styleItems.length < 1) return <div></div>

        return (
            <div className="style-item">
                <div style={{ marginBottom: '2px' }}>
                    <span className='pricing-title'>Style Options</span>
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="siding-item">
                    <div className="select-panel">
                        {
                            styleItems.map((shed, sI) =>
                                <div key={sI} className="pricing-item-container tooltip-div" onClick={(e) => this.handleSelectStyle(shed)}>
                                    <div className='size-image'>
                                        <img src={process.env.REACT_APP_SERVER_URL + shed.shed_img}
                                            alt="Style Image" className={selected_style === shed.id ? 'selected' : ''} />
                                    </div>
                                    <span className="tooltip-content" style={{ top: '25px' }}>{shed.shed_name}</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div >
        )
    }
}

export default MainComp;