import React, { Component } from 'react';
import "../css/project-comp.scss";

class MainComp extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    handleInput = (e, shed_id, sidingId) => {
        const { sidingCosts } = this.props;
        const key = sidingId;
        const value = e.target.value?.replace('$ ', '').replace(/^0+/, '');
        sidingCosts[shed_id] = { ...sidingCosts[shed_id], [key]: value };

        this.props.handleChangeSidingCosts(sidingCosts);
    }

    validateRiskInput = (e) => {
        const input = e.target;
        let value = input.value.replace(/[^-0-9.]/g, '');
        input.value = value;
    }

    toggleMultiplyShow = (e, shed_id) => {
        const { isSidingSizeMultiplyers, isSidingSizePercentages } = this.props;
        if (e.target.checked) {
            isSidingSizeMultiplyers[shed_id] = e.target.checked;
            isSidingSizePercentages[shed_id] = !e.target.checked;
        } else {
            isSidingSizeMultiplyers[shed_id] = e.target.checked;
        }

        const type = 'isSidingSizeMultiplyers';
        this.props.handleMultiplyer(type, isSidingSizeMultiplyers);
    }

    togglePercentageShow = (e, shed_id) => {
        const { isSidingSizeMultiplyers, isSidingSizePercentages } = this.props;
        if (e.target.checked) {
            isSidingSizeMultiplyers[shed_id] = !e.target.checked;
            isSidingSizePercentages[shed_id] = e.target.checked;
        } else {
            isSidingSizePercentages[shed_id] = e.target.checked;
        }

        const type = 'isSidingSizePercentages';
        this.props.handlePercentage(type, isSidingSizePercentages);
    }

    render() {
        const { json_data, project_data, selected_style, sidingCosts, isSidingSizeMultiplyers, isSidingSizePercentages } = this.props
        const shed_id = selected_style || json_data?.load_shed
        if (!shed_id) return <div></div>

        const loadObj = json_data[shed_id]
        if (!loadObj || !loadObj.sidingColorSet) return <div></div>

        let sidingItems = []
        if (loadObj.sidingColorSet) {
            loadObj.sidingColorSet.map(item => {
                item.sidings.map(siding => {
                    let sidingItem = project_data.siding_model.find(model => model.id === siding.id)
                    sidingItems.push({ siding: sidingItem, textures: [item.texture] })
                    return true;
                })
                return true;
            })
        }

        return (
            <div className="color-item">
                <div style={{ marginBottom: '2px' }}>
                    <span className='pricing-title'>Siding Options</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.toggleMultiplyShow(e, shed_id)}
                        checked={isSidingSizeMultiplyers[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>Multiply Price by SQ FT</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.togglePercentageShow(e, shed_id)}
                        checked={isSidingSizePercentages[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>By Percentage</span>
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="siding-item">
                    <div className="select-panel">
                        {
                            sidingItems.map((siding, sI) =>
                                <div key={sI} className="pricing-item-container tooltip-div">
                                    <div className="size-image">
                                        <img src={process.env.REACT_APP_SERVER_URL + siding.siding.siding_img}
                                            alt="Siding" />
                                    </div>
                                    <span className="tooltip-content">{siding.siding.siding_name}</span>
                                    <input
                                        type="text"
                                        className='pricing-item-price'
                                        placeholder=''
                                        value={`${isSidingSizePercentages[shed_id] ? '' : '$ '}${sidingCosts[shed_id] ? (sidingCosts[shed_id][siding.siding.id] || '0') : '0'}`}
                                        onChange={(e) => this.handleInput(e, shed_id, siding.siding.id)}
                                        onInput={this.validateRiskInput}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default MainComp;