import React from 'react'
import "../css/pricing.scss"


const Comp = () =>
    <div className="pricing-comp">
        <div className="header-part header-example terms-service">
            <div className="header-title">
                <h2>Privacy Policy</h2>
            </div>
        </div>

        <div className='price-contact terms-service'>
            <br />
            <br />
            <p>
                At Digital Shed Builder, accessible from https://digitalshedbuilder.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Digital Shed Builder and how we use it.
            </p>
            <br />
            <p>
                If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
            </p>
            <br />
            <p>
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in Digital Shed Builder. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the Free Privacy Policy Generator.
            </p>
            <br />
            <h5>
                Consent
            </h5>
            <p>
                By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
            <p>  Information we collect </p>
            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
            <br />
            <p>   If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
            <br />
            <p>  When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
            <br />

            <h5>
                How we use your information
            </h5>
            <p>
                We use the information we collect in various ways, including to: </p>
            <p>Provide, operate, and maintain our website</p>
            <p>Improve, personalize, and expand our website</p>
            <p>Understand and analyze how you use our website</p>
            <p>Develop new products, services, features, and functionality</p>
            <p>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</p>
            <p>Send you emails</p>
            <p>Find and prevent fraud</p>
            <p>Log Files</p>
            <p>
                Digital Shed Builder follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
            </p>
            <br />

            <h5>
                Cookies and Web Beacons
            </h5>

            <p>
                Like any other website, Digital Shed Builder uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
            <p>Advertising Partners Privacy Policies</p>
            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Digital Shed Builder.</p> <br />

            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Digital Shed Builder, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
            <p>Note that Digital Shed Builder has no access to or control over these cookies that are used by third-party advertisers.</p>
            <p>Third Party Privacy Policies</p>
            <p>Digital Shed Builder's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p><br />

            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
            <p>CCPA Privacy Rights (Do Not Sell My Personal Information)</p>
            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
            <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
            <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p><br />

            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
            <p>GDPR Data Protection Rights</p>
            <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
            <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
            <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
            <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
            <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
            <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
            <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p><br />

            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
            <p>Children's Information</p>
            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p><br />

            <p>Digital Shed Builder does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
            <br />
            <h4>
                Disclaimer
            </h4>
            <p>
                Last updated: September 11, 2022
            </p>
            <br />
            <p>
                Interpretation and Definitions
            </p>
            <br />

            <h5>
                Interpretation
            </h5>
            <p>
                The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>
            <br />

            <h5>
                Definitions
            </h5>
            <p>
                For the purposes of this Disclaimer:</p>
            <p>Company (referred to as either "the Company", "We", "Us" or "Our" in this Disclaimer) refers to Digital Shed Builder, LLC, 724 E Gilmore Rd.</p>
            <p>Service refers to the Website.</p>
            <p>You means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            <p>Website refers to Digital Shed Builder, accessible from https://digitalshedbuilder.com</p>
            <br />

            <h5>
                Disclaimer
            </h5>
            <p>The information contained on the Service is for general information purposes only.</p>
            <p>The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
            <p>In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice. This Disclaimer has been created with the help of the Disclaimer Generator.</p>
            <p>The Company does not warrant that the Service is free of viruses or other harmful components.</p>
            <br />

            <h5>
                External Links Disclaimer
            </h5>
            <p>
                The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.</p>
            <p>Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>
            <br />

            <h5>
                Errors and Omissions Disclaimer
            </h5>
            <p>The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to insure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.</p>
            <p>The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.</p>
            <br/>

            <h5>
                Fair Use Disclaimer
            </h5>
            <p>The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making such material available for criticism, comment, news reporting, teaching, scholarship, or research.</p>
            <p>The Company believes this constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the United States Copyright law.</p>
            <p>If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner.</p>
            <br />
            <h5>
                Views Expressed Disclaimer
            </h5>
            <p>The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.</p>
            <p>Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserves the right to delete any comment for any reason whatsoever.</p>
            <br />
            <h5>
                No Responsibility Disclaimer
            </h5>
            <p>The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>
            <p>In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.</p>
            <br />
            <h5>
                "Use at Your Own Risk" Disclaimer
            </h5>
            <p>All information in the Service is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.</p>
            <p>The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
            <br />
            <h5>
                Contact Us
            </h5>
            <p>If you have any questions about this Disclaimer, You can contact Us:</p>
            <p>By email: support@digitalshedbuilder.com</p>
            <p>By phone number: 209.485.0138</p>
            <br />
        </div>
    </div>

export default Comp
