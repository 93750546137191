import React from 'react'
import FirstPageComp from './home-page'
import FeaturesPageComp from './features-page'
import ExamplePageComp from './example'
import FourthPageComp from './contact-uspage'
import PricePageComp from './pricingpage'
import PaymentPageComp from './payment'
import SignInComp from './sign-in'
import PrivacyPolicyComp from './privacy-policy'
import TermsServiceComp from './terms-service'
import DemoPageComp from './demo-page'
import ProjectPageComp from './member-page'
import AdminAffiliatePageComp from './admin-affiliate-page'
import AdminMemberPageComp from './admin-member-page'


const FirstPage = () => <FirstPageComp />
const SecondPage = () => <FeaturesPageComp />
const ExamplePage = () => <ExamplePageComp />
const FourthPage = () => <FourthPageComp />
const PricePage = () => <PricePageComp />
const PaymentPage = () => <PaymentPageComp />
const SignInPage = () => <SignInComp />
const PrivacyPage = () => <PrivacyPolicyComp />
const TermsPage = () => <TermsServiceComp />
const DemoPage = () => <DemoPageComp />
const MemberPage = () => <ProjectPageComp />
const AdminAffiliatePage = () => <AdminAffiliatePageComp />
const AdminMemberPage = () => <AdminMemberPageComp />

export { FirstPage, SecondPage, ExamplePage, FourthPage, PricePage, PaymentPage, SignInPage, PrivacyPage, TermsPage, DemoPage, MemberPage, AdminMemberPage, AdminAffiliatePage }