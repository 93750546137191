import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false,
//       position: 'top' as const,
//     },
//     title: {
//       display: false,
//       text: 'Customer Views',
//     },
//   },
//   scales: {
//     y: {
//       suggestedMin: 0,
//       suggestedMax: 10,      
//       border: {
//         "display": false
//       }
//     },
   
//   }
// };


function App(props: any) {
  return <div>
    <h3 >Views</h3>
    <Bar options={props.option} data={props.data} height={100} redraw={props.redraw} />
  </div>;
}


export default App;
