import React from 'react'
import logo from './DSB_ICON.png'

import { BrowserRouter, Route, Link, NavLink, Routes, Navigate } from 'react-router-dom'
import {
  FirstPage, SecondPage, ExamplePage, FourthPage, PricePage,
  PaymentPage, SignInPage, PrivacyPage, TermsPage, DemoPage, MemberPage, AdminMemberPage, AdminAffiliatePage
} from './components/pages'
import { LayoutOne } from './components/layouts'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaFacebookF, FaInstagram, FaLongArrowAltRight, FaUser } from 'react-icons/fa';
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { IoCloseSharp } from "react-icons/io5";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { connect } from "react-redux";
import { successClose } from "./store/actions/alerts";
import { logout, authCheckState } from "./store/actions/auth";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss'
// const [modalShow, setModalShow] = React.useState(false);

const menus = [
  { title: "Plans & Pricing", link: "/pricing" },
  { title: "Examples", link: "/example" },
  { title: "Features", link: "/features" },
  { title: "Contact Us", link: "/contact-us" }

]

const ProtectedRoute = ({ children, authUser }) => {
  if (!authUser.id) {
    return <Navigate to="/" replace />;
  }
  return children;
};

if (process.env.NODE_ENV === 'production')
  console.log = function () { }

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      userEmail: "",
      userPwd: "",
      mobileMenuOpen: false
    }
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  // tryLogin = () => {
  //   console.log("user auth : ", this.state.userEmail)
  //   if (this.state.userEmail === "Jon Vaner" && this.state.userPwd === "12345") {
  //     this.setModalShow(false)
  //   }
  // }

  componentDidMount = () => {
    // console.log("root path : ", window.location.pathname)
    // this.setModalShow(true)
    const { auth_check } = this.props

    auth_check();

  }

  setModalShow = (modalShow) => {
    this.setState({ modalShow })
  }

  alertClose = () => {
    this.props.successClose()
  }

  menuOpen = () => {
    this.setState({ mobileMenuOpen: true })
  }

  menuClick = () => {
    this.setState({ mobileMenuOpen: false })
  }

  logout = (e) => {
    e.preventDefault()
    this.props.logout()
    window.location.href = "/sign-in"
  }


  goToMemberPage = () => {
    const { authUser } = this.props
    console.log("auth user : ", authUser)

    if (authUser.subscribe_status) {
      window.location.href = "/member-page"
    } else {
      window.location.href = `/payment?customer=${authUser.id}&step=1`
    }

  }

  cancelSubscription = () => {
    const { authUser } = this.props
    window.location.href = `/payment?customer=${authUser.id}&amount=${authUser.subscribe_amount.value}&step=3`
  }



  render() {
    const { alertOpen, messageTypes, messageContent, authUser } = this.props
    const { mobileMenuOpen } = this.state
    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
      <div className={"App " + (mobileMenuOpen ? "mobile-menu-open" : "")}>
        <BrowserRouter>
          <header>
            <div className="search-bar">
              <ul className="nav navbar-nav navbar-right">
                {authUser.id && <li className='login-link'>
                  <div className="cancel-subscription" >
                    <button onClick={this.cancelSubscription} >
                      Manage Subscription
                    </button>
                  </div>
                </li>}

                <li className="hidden-xs">
                  <a href="https://www.facebook.com/people/Digital-Shed-Builder/100081949742401/" target="_blank" rel="noopener noreferrer" aria-label='Facebook'>
                    <FaFacebookF />
                  </a>
                </li>
                <li className="hidden-xs">
                  <a href="https://instagram.com/digitalshedbuilder?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer" aria-label='Instagram'>
                    <FaInstagram />
                  </a>
                </li>
                <li className='login-link'>
                  {authUser.id ?
                    <>
                      <div className="link-login hidden-xs" >
                        <button onClick={this.logout} aria-label='logout' >LOGOUT</button>
                        <button onClick={this.goToMemberPage} className="icon-container arrow" aria-label='arrow'>
                          <FaUser />
                        </button>
                      </div>
                    </>
                    :
                    <div className="link-login hidden-xs" onClick={() => window.location.href = "/sign-in"}>
                      <button aria-label='login'>LOGIN</button>
                      <button className="icon-container arrow" aria-label='arrow'>
                        <FaLongArrowAltRight />
                      </button>
                    </div>
                  }
                </li>
              </ul>
            </div>
            <div className="App-header">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
              <ul>
                {
                  menus.map((menu, i) => <li key={i} ><NavLink to={menu.link}>{menu.title}</NavLink></li>)
                }
                <li>
                  <button className='mobile-menu' onClick={this.menuOpen.bind(this)}>
                    <FontAwesomeIcon icon={faBars} />
                  </button>
                </li>
              </ul>
            </div>
          </header>
          <Routes>
            <Route exact path="/" element={
              <LayoutOne >
                <FirstPage />
              </LayoutOne>
            } />
            <Route path="/pricing" element={
              <LayoutOne>
                <PricePage />
              </LayoutOne>
            } />
            <Route path="/features" element={
              <LayoutOne>
                <SecondPage />
              </LayoutOne>
            } />
            <Route path="/example" element={
              <LayoutOne>
                <ExamplePage />
              </LayoutOne>
            } />
            <Route path="/contact-us" element={
              <LayoutOne >
                <FourthPage />
              </LayoutOne>
            } />
            <Route path="/sign-in" element={
              <LayoutOne >
                <SignInPage />
              </LayoutOne>
            } />

            <Route path="/payment" element={
              <LayoutOne >
                <PaymentPage />
              </LayoutOne>
            } />

            <Route path="/privacy-policy" element={
              <LayoutOne >
                <PrivacyPage />
              </LayoutOne>
            } />
            <Route path="/terms-condition" element={
              <LayoutOne >
                <TermsPage />
              </LayoutOne>
            } />

            <Route path="/demo-page" element={
              <LayoutOne >
                <DemoPage />
              </LayoutOne>
            } />

            <Route path="/member-page" element={
              <ProtectedRoute authUser={authUser}>
                <LayoutOne >
                  <MemberPage />
                </LayoutOne>
              </ProtectedRoute>
            } />

            <Route path='*' element={
              <Navigate to="/" />
            } />

            <Route path="/admin/member-page" element={
              // <ProtectedRoute authUser={authUser}>
              <LayoutOne >
                <AdminMemberPage />
              </LayoutOne>
              // </ProtectedRoute>
            } />

            <Route path="/admin/affiliate-page" element={
              // <ProtectedRoute authUser={authUser}>
              <LayoutOne >
                <AdminAffiliatePage />
              </LayoutOne>
              // </ProtectedRoute>
            } />

          </Routes>
          {mobileMenuOpen && <div className="mobile-contents">
            <div className='menu-title'>
              <img src={logo} alt="logo" />
              <h3>
                Digital Shed Builder
              </h3>
              <button onClick={this.menuClick.bind(this)}>
                <IoCloseSharp />
              </button>
            </div>
            <ul>
              {
                menus.map((menu, i) => <li key={i} ><NavLink to={menu.link} onClickCapture={this.menuClick.bind(this)}>{menu.title}</NavLink></li>)
              }
            </ul>
          </div>
          }
        </BrowserRouter>

        <Modal
          show={this.state.modalShow}
          onHide={() => this.setModalShow(true)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              User Authentication
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="user-auth">
              <input type="text" name="userEmail" value={this.state.userEmail} placeholder="User Name" onChange={this.handleInputChange.bind(this)} />
              <input type="password" name="userPwd" value={this.state.userPwd} placeholder="User Password" onChange={this.handleInputChange.bind(this)} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => window.location.href = "/sign-in"}>Login</Button>
          </Modal.Footer>
        </Modal>

        <Snackbar open={alertOpen} autoHideDuration={5000} onClose={this.alertClose}>
          <Alert onClose={this.alertClose} severity={messageTypes} sx={{ width: '100%' }}>
            {messageContent}
          </Alert>
        </Snackbar>

      </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    alertOpen: state.alerts.open,
    messageContent: state.alerts.message,
    messageTypes: state.alerts.types,
    authUser: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    successClose: () => dispatch(successClose()),
    logout: () => dispatch(logout()),
    auth_check: () => dispatch(authCheckState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

