import React from 'react'
import { Link } from 'react-router-dom'



const Comp = () =>
    <div className="first-page">
        <div className="header-part header-ecommerce">
            <div className="header-title">
                <h2><b>Features</b></h2>
                <h3>Powerful tools to capture more new leads and drive more sales online.</h3>
            </div>
        </div>

        <div className="main-content" style={{ paddingBottom: "3vh" }}>
            <div className="config-content">
                <div className="shed-ad">
                    <div className="half-side">
                        <img src={require("../../assets/features/feature-1.jpg")} alt='feature-img' />
                        <div className='content-block'>
                            <p className="side-title">
                                Unparalleled <b>Quality</b>
                            </p>
                            <p>
                                We leverage the most recent and relevant 3D technology to provide a stunning and realistic experience not found with other configurators. Don’t settle for outdated graphics! Give your customers a quality experience that will set your structures apart from the competition.
                            </p>
                        </div>
                        <img src={require("../../assets/features/feature-3.jpg")} alt='feature-img' />
                    </div>
                    <div className="half-side">
                        <br />
                        <br />
                        <div className='content-block'>
                            <p className="side-title">
                                Support for all types of <b>Custom Structures!</b>
                            </p>
                            <p>                                
                                Do you sell Play Sets, Metal Buildings, Chicken Coops, Dog Kennels, Horse Barns, or other custom structures? Our next-generation technology allows us to accurately and beautifully represent ANY product. There is no design that is too complex or detailed for us here at Digital Shed Builder. Curious about a custom project? Get in touch with us today!                            
                            </p>
                        </div>
                        <br />
                        <br />
                        <img src={require("../../assets/features/feature-2.jpg")} alt='feature-img' />
                        <div className='content-block'>
                            <p className="side-title">
                                <b>Speed</b> and Compatibility
                            </p>
                            <p>
                                We all know that potential customers visiting your website have a limited attention span. Don’t keep them waiting! Our 3D Tools load lightning fast and are compatible with all devices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="main-content" style={{ background: '#eee', padding: '2vw' }}>
            <div className="config-content">
                <p className='side-title text-center'>
                    <b>Powerful</b> Sales Tools
                </p>
                <div className='img-box'>
                    <img src={require("../../assets/features/feature-4.jpg")} alt='feature-img'/>
                    <img src={require("../../assets/features/feature-5.jpg")} alt='feature-img'/>
                    <img src={require("../../assets/features/feature-6.jpg")} alt='feature-img'/>
                </div>
                <div className='img-box'>
                    <p>
                        Interactive pricing estimates show customers in real time how size, feature, and accessory options impact overall cost. This allows potential buyers to easily obtain the information they need to make an educated purchase decision from the convenience of their own home. “Buy Now” or “Get a Quote” features allow you to capture more leads through your website, drive more online sales, and get precise information about what potential customers are interested in purchasing.
                    </p>
                </div>
            </div>
        </div>

        <div className="main-content" style={{ padding: '2vw' }}>
            <div className="config-content">
                <p className='side-title text-center'>
                    <b>Marketing</b> Insights
                </p>
                <div className='img-box marketing-insight'>
                    <img src={require("../../assets/features/feature7-1.png")} alt='feature-img'/>
                    <img src={require("../../assets/features/feature7-2.png")} alt='feature-img'/>
                </div>
                <div className='img-box'>
                    <p>
                        Is your business website and online presence generating the kind of sales that it could? Access all of your customer quote requests and new leads through one convenient dashboard. Get valuable insights on what products and options are most popular. Collect lead data for new marketing and retargeting campaigns. “Work smarter, not harder!” - Allen F. Morgenstern.
                    </p>
                </div>
                <br/>
                <p className='text-center'>
                    <Link to="/contact-us" className="schedule-call">
                        <span >SCHEDULE A CALL</span>
                    </Link>
                </p>
                <br/>
            </div>
        </div>
        
    </div>

export default Comp
