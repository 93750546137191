import React, { Component } from 'react';
import "../css/project-comp.scss";

const orderFunc = (a, b) => {
    if (a.order > b.order)
        return 1
    else if (a.order < b.order)
        return -1
    return 0
}

const convertThumb = (img_path) => {
    const file_names = img_path.split("/")
    file_names[file_names.length - 1] = "thumb_" + file_names[file_names.length - 1]
    return file_names.join("/")
}

class MainComp extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    handleInput = (e, shed_id, textureId) => {
        const { roofingColorCosts } = this.props;
        const key = textureId;
        const value = e.target.value?.replace('$ ', '').replace(/^0+/, '');
        roofingColorCosts[shed_id] = { ...roofingColorCosts[shed_id], [key]: value };

        this.props.handleChangeRoofingColorCosts(roofingColorCosts);
    }

    validateRiskInput = (e) => {
        const input = e.target;
        let value = input.value.replace(/[^-0-9.]/g, '');
        input.value = value;
    }

    toggleMultiplyShow = (e, shed_id) => {
        const { isRoofingColorSizeMultiplyers, isRoofingColorSizePercentages } = this.props;
        if (e.target.checked) {
            isRoofingColorSizeMultiplyers[shed_id] = e.target.checked;
            isRoofingColorSizePercentages[shed_id] = !e.target.checked;
        } else {
            isRoofingColorSizeMultiplyers[shed_id] = e.target.checked;
        }

        const type = 'isRoofingColorSizeMultiplyers';
        this.props.handleMultiplyer(type, isRoofingColorSizeMultiplyers);
    }

    togglePercentageShow = (e, shed_id) => {
        const { isRoofingColorSizeMultiplyers, isRoofingColorSizePercentages } = this.props;
        if (e.target.checked) {
            isRoofingColorSizeMultiplyers[shed_id] = !e.target.checked;
            isRoofingColorSizePercentages[shed_id] = e.target.checked;
        } else {
            isRoofingColorSizePercentages[shed_id] = e.target.checked;
        }

        const type = 'isRoofingColorSizePercentages';
        this.props.handlePercentage(type, isRoofingColorSizePercentages);
    }

    render() {
        const { json_data, project_data, selected_style, roofingColorCosts, isRoofingColorSizeMultiplyers, isRoofingColorSizePercentages } = this.props;
        const shed_id = selected_style || json_data?.load_shed
        if (!shed_id) return <div></div>

        const loadObj = json_data[shed_id]
        if (!loadObj || !loadObj.roofingColorSet) return <div></div>

        if (!loadObj.selectedRoofingColorItem || !loadObj.roofingColorSet) return <div></div>

        let colorItems = []
        loadObj.roofingColorSet.map(item => {
            const items = project_data.roofing_texture_model.filter(tex_item => tex_item.id === item.texture)[0]?.texture_imgs.filter(item => item.img_type === 0);
            if (items && items.length > 0) {
                colorItems.push(...items);
                colorItems?.sort(orderFunc)
            }
            return true;
        })

        // getting unique colorItems
        let flags = [], output = [];
        for (let i = 0; i < colorItems.length; i++) {
            if (flags[colorItems[i].id]) continue;
            flags[colorItems[i].id] = true;
            if (colorItems[i]) {
                output.push(colorItems[i]);
            }
        }

        colorItems = output;
        
        // let colorItem = undefined
        // let colorIndex = loadObj.selectedRoofingColorItem[0]
        // if (loadObj.roofingColorSet[colorIndex]) {
        //     colorItem = loadObj.roofingColorSet[colorIndex]
        // }

        // let colorItems = []
        // if (colorItem) {
        //     colorItems = project_data.roofing_texture_model.filter(tex_item => tex_item.id === colorItem.texture)[0]?.texture_imgs.filter(item => item.img_type === 0)
        //     colorItems?.sort(orderFunc)
        // }

        if (!colorItems || colorItems?.length < 1) return <div></div>

        return (
            <div className="color-item">
                <div style={{ marginBottom: '2px' }}>
                    <span className='pricing-title'>Roof Color Options</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.toggleMultiplyShow(e, shed_id)}
                        checked={isRoofingColorSizeMultiplyers[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>Multiply Price by SQ FT</span>
                    <input
                        type='checkbox'
                        onChange={(e) => this.togglePercentageShow(e, shed_id)}
                        checked={isRoofingColorSizePercentages[shed_id] || false}
                        style={{ marginLeft: '30px', marginRight: '12px' }}
                    />
                    <span style={{ fontSize: '18px' }}>By Percentage</span>
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="select-panel">
                    {
                        colorItems?.map((texture, t_id) => (
                            texture.img_type === 0) &&
                            <div key={t_id} className='pricing-item-container  tooltip-div'>
                                <div
                                    style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL + convertThumb(texture.texture_img)})`, width: '75px', height: '75px', borderRadius: '45px', cursor: 'pointer' }}
                                >
                                </div>
                                <span className="tooltip-content">{texture.img_key}</span>
                                <input
                                    type="text"
                                    className='pricing-item-price'
                                    placeholder=''
                                    value={`${isRoofingColorSizePercentages[shed_id] ? '' : '$ '}${roofingColorCosts[shed_id] ? (roofingColorCosts[shed_id][texture.id] || '0') : '0'}`}
                                    onChange={(e) => this.handleInput(e, shed_id, texture.id)}
                                    onInput={this.validateRiskInput}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default MainComp;